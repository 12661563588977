define('aira-mgmt/adapters/setting', ['exports', 'aira-mgmt/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
			return [this.host, this.namespace, 'mgmt', id, 'settings'].join('/');
		},
		urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
			return [this.host, this.namespace, 'mgmt', id, 'settings'].join('/');
		}
	});
});