define('aira-mgmt/services/platform', ['exports', 'aira-mgmt/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    session: Ember.inject.service('session'),

    doGet: function doGet(endpoint) {
      var _this = this;

      var self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          url: [_environment.default.APP.host, _environment.default.APP.namespace, endpoint].join('/'),
          method: 'GET',
          contentType: 'application/json',
          headers: _this._getHeaders(self),
          dataType: 'json',
          success: function success(responseData) {
            resolve(responseData);
          },
          error: function error(jqXHR, textStatus, errorThrown) {
            self._error(reject, jqXHR, textStatus, errorThrown);
          }
        });
      });
    },
    doPost: function doPost(endpoint, data) {
      var _this2 = this;

      var self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          url: [_environment.default.APP.host, _environment.default.APP.namespace, endpoint].join('/'),
          method: 'POST',
          contentType: 'application/json',
          headers: _this2._getHeaders(self),
          dataType: 'json',
          data: JSON.stringify(data),
          success: function success(responseData) {
            resolve(responseData);
          },
          error: function error(jqXHR, textStatus, errorThrown) {
            self._error(reject, jqXHR, textStatus, errorThrown);
          }
        });
      });
    },
    doPut: function doPut(endpoint, data) {
      var url = [_environment.default.APP.host, _environment.default.APP.namespace, endpoint].join('/');
      return this.doPutRawUrl(url, data);
    },
    doPutRawUrl: function doPutRawUrl(endpoint, data) {
      var _this3 = this;

      var self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          url: endpoint,
          method: 'PUT',
          contentType: 'application/json',
          headers: _this3._getHeaders(self),
          dataType: 'json',
          data: JSON.stringify(data),
          success: function success(responseData) {
            resolve(responseData);
          },
          error: function error(jqXHR, textStatus, errorThrown) {
            self._error(reject, jqXHR, textStatus, errorThrown);
          }
        });
      });
    },
    doDelete: function doDelete(endpoint, data) {
      var _this4 = this;

      var self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          url: [_environment.default.APP.host, _environment.default.APP.namespace, endpoint].join('/'),
          method: 'DELETE',
          contentType: 'application/json',
          headers: _this4._getHeaders(self),
          dataType: 'json',
          data: JSON.stringify(data),
          success: function success(responseData) {
            resolve(responseData);
          },
          error: function error(jqXHR, textStatus, errorThrown) {
            self._error(reject, jqXHR, textStatus, errorThrown);
          }
        });
      });
    },
    _error: function _error(reject, jqXHR) {
      switch (jqXHR.status) {
        case 0:
          console.error('Platform is offline');
          reject({ errorCode: 0, errorMessage: 'Platform gone missing' });
          break;
        case 503:
          console.error('503 Service Unavailable received from Platform');
          reject({ errorCode: 0, errorMessage: '503 Service Unavailable received from Platform' });
          break;
        case 500:
          console.error('500 Internal Server Error received from Platform');
          reject({ errorCode: 0, errorMessage: '500 Internal Server Error received from Platform' });
          break;
        case 401:
          console.error('401 Unauthorized received from Platform');
          this._logout();
          break;
        case 403:
          console.error('403 Forbidden received from Platform');
          this._logout();
          break;
        default:
          reject({
            errorCode: jqXHR.responseJSON.response.errorCode,
            errorMessage: jqXHR.responseJSON.response.errorMessage
          });
          break;
      }
    },
    _generateId: function _generateId() {
      // Large number without leading zeroes
      return Math.floor(100000000 + Math.random() * 900000000);
    },


    _getHeaders: function _getHeaders(self) {
      var headers = {
        'X-API-Key': _environment.default.APP.X_API_Key,
        'X-Client-Id': _environment.default.APP.X_Client_Id,
        'X-Aira-Token': self.get('session.data.authenticated.token'),
        'X-User-Id': self.get('session.data.authenticated.id'),
        'X-Trace-Id': self._generateId()
      };

      return headers;
    },

    _logout: function _logout() {
      console.log('Logging out...');
      this.get("session").invalidate();
    }
  });
});