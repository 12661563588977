define('aira-mgmt/accounts/account/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.removeNotSetLocalizations = removeNotSetLocalizations;
  exports.addAllLangLocalizations = addAllLangLocalizations;
  exports.addMissingLangLocalizations = addMissingLangLocalizations;
  exports.validateEnglishLocalization = validateEnglishLocalization;
  exports.validateLocalization = validateLocalization;
  exports.validateAttributesLocalized = validateAttributesLocalized;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Route.extend({

    initialTab: null,

    platform: Ember.inject.service(),
    beforeModel: function beforeModel(t) {
      // Handles setting the activeTab when deep-linking
      // targetName is: users.user.{activeTab}.index
      this.initialTab = t.targetName.split('.')[2];
    },


    model: function model(p) {
      var _this = this;

      return Ember.RSVP.hash({
        account: new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('platform').doGet('search/account?type=ACCOUNT_ID&q=' + p.account_id).then(function (response) {
            resolve(response.users[0]);
          }, function (_ref) {
            var errorCode = _ref.errorCode,
                errorMessage = _ref.errorMessage;

            reject({ errorCode: errorCode, errorMessage: errorMessage });
            return [];
          });
        })

      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('activeTab', this.initialTab);
      this.modelFor("accounts.account").account.isBusiness = model.account.accountType === "BUSINESS" ? true : false;
    },

    actions: {
      loading: function loading(transition) {
        var controller = this.get('controller');
        if (controller) {
          controller.set('isLoading', true);
          transition.promise.finally(function () {
            controller.set('isLoading', false);
          });
        }
      },
      willTransition: function willTransition(t) {
        // Handles keeping the activeTab updated when changing
        // targetName is: accounts.account.{activeTab}.index
        var target = t.targetName.split('.')[2];
        this.controller.set('activeTab', target);
      }
    }
  });
  function removeNotSetLocalizations(attributesLocalized) {
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {

      for (var _iterator = Object.entries(attributesLocalized)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _ref2 = _step.value;

        var _ref3 = _slicedToArray(_ref2, 2);

        var language = _ref3[0];
        var attr = _ref3[1];

        if (!attr.localization.name && !attr.localization.description && !attr.localization.explorerMessage) {
          delete attributesLocalized[language];
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return attributesLocalized;
  }

  function addAllLangLocalizations() {

    // TODO: we would benefit here from a list of supported languages
    // - the list can be returned as part of this API or a new API to get languages only
    // - alternative would be that this API would return these default structures which seems dirty
    var attributesLocalized = {};
    attributesLocalized.English = defaultLocalizationStructure();
    attributesLocalized.French = defaultLocalizationStructure();
    attributesLocalized.Spanish = defaultLocalizationStructure();

    return attributesLocalized;
  }

  function addMissingLangLocalizations(attributesLocalized) {

    // when localizations are not present, initialize with default json
    // TODO: we would benefit here from a list of supported languages
    // - the list can be returned as part of this API or a new API to get languages only
    // - alternative would be that this API would return these default structures which seems dirty
    if (!('English' in attributesLocalized)) {
      attributesLocalized.English = defaultLocalizationStructure();
    }
    if (!('French' in attributesLocalized)) {
      attributesLocalized.French = defaultLocalizationStructure();
    }
    if (!('Spanish' in attributesLocalized)) {
      attributesLocalized.Spanish = defaultLocalizationStructure();
    }

    console.log('attr: ', attributesLocalized);

    return attributesLocalized;
  }

  function defaultLocalizationStructure() {
    return { id: null, localization: { name: null, description: null, explorerMessage: null } };
  }

  // English localization is mandatory, should always be present, never deleted
  function validateEnglishLocalization(localization) {

    var dataIsGood = true;
    if (!localization || !localization.name || !localization.explorerMessage) {
      dataIsGood = false;
    }
    return dataIsGood;
  }

  function validateLocalization(localization) {

    var dataIsGood = true;

    if (localization && localization.description && (!localization.name || !localization.explorerMessage) || localization && localization.name && !localization.explorerMessage || localization && localization.explorerMessage && !localization.name) {
      dataIsGood = false;
    }

    return dataIsGood;
  }

  function validateAttributesLocalized(controllerAttrLoc) {

    var dataIsGood = true;
    var errorMessage = [];

    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = Object.entries(controllerAttrLoc)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var _ref4 = _step2.value;

        var _ref5 = _slicedToArray(_ref4, 2);

        var language = _ref5[0];
        var attr = _ref5[1];

        if (language === 'English') {
          if (!validateEnglishLocalization(attr.localization)) {
            dataIsGood = false;
            errorMessage.push('English Product Name and Explorer Message cannot be empty');
          }
        } else {
          if (!validateLocalization(attr.localization)) {
            dataIsGood = false;
            errorMessage.push(language + ' Product Name and Explorer Message cannot be empty');
          }
        }
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    return [dataIsGood, errorMessage];
  }
});