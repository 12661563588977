define('aira-mgmt/accounts/account/products/product/new/route', ['exports', 'aira-mgmt/utils/validate-dates', 'aira-mgmt/helpers/change-PST-to-ISO', 'aira-mgmt/accounts/account/route'], function (exports, _validateDates, _changePSTToISO, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.set('controller.attributesLocalized', (0, _route.addAllLangLocalizations)());
    },


    actions: {
      addCancel: function addCancel() {
        this.transitionTo('accounts.account.products.index');
      },
      addProduct: function addProduct() {
        var _this = this;

        var accountId = this.modelFor('accounts.account').account.accountId;
        console.log('ADD PRESSED', accountId);

        var dataIsGood = true;
        var errorMessage = [];
        if (this.get('controller.class') === null) {
          dataIsGood = false;
          errorMessage.push('Production or promotion not specified');
        }
        if (this.get('controller.key') === '' || this.get('controller.key') === null) {
          dataIsGood = false;
          errorMessage.push('Missing product key');
        }
        if (this.get('controller.agentMessage') === '' || this.get('controller.agentMessage') === null) {
          dataIsGood = false;
          errorMessage.push('Missing agent message');
        }

        var effectiveFromError = (0, _validateDates.validateDate)(this.get('controller.effectiveFrom'), 'effective from', false);
        if (effectiveFromError) {
          dataIsGood = false;
          errorMessage.push(effectiveFromError);
        }

        var effectiveToValue = this.get('controller.effectiveTo');
        if (effectiveToValue !== '' && effectiveToValue != null) {
          var effectiveToError = (0, _validateDates.validateDate)(effectiveToValue, 'effective to', false);
          if (effectiveToError) {
            dataIsGood = false;
            errorMessage.push(effectiveToError);
          }
        }

        if (this.get('controller.durationAllowed') && parseInt(this.get('controller.durationAllowed')) <= 0) {
          dataIsGood = false;
          errorMessage.push('Duration per month value must be a valid number greater than 0');
        }
        if (this.get('controller.callLimitCheckbox')) {
          if (this.get('controller.durationPerCall') === '' || this.get('controller.durationPerCall') === null) {
            dataIsGood = false;
            errorMessage.push('Missing enforced maximum call duration');
          } else {
            var value = parseInt(this.get('controller.durationPerCall'));
            if (value <= 0 || isNaN(value)) {
              dataIsGood = false;
              errorMessage.push('Duration per call value must be a valid number greater than 0');
            }
          }
        }
        if (this.get('controller.callFrequencyCheckbox')) {
          if (this.get('controller.callsPerPeriod') === '' || this.get('controller.callsPerPeriod') === null) {
            dataIsGood = false;
            errorMessage.push('Missing enforced calls per period');
          } else {
            var _value = parseInt(this.get('controller.callsPerPeriod'));
            if (_value <= 0 || isNaN(_value)) {
              dataIsGood = false;
              errorMessage.push('Calls per period value must be a valid number greater than 0');
            }
          }
          if (this.get('controller.callPeriodLength') === '' || this.get('controller.callPeriodLength') === null) {
            dataIsGood = false;
            errorMessage.push('Missing enforced call period length');
          } else {
            var _value2 = parseInt(this.get('controller.callPeriodLength'));
            if (_value2 <= 0 || isNaN(_value2)) {
              dataIsGood = false;
              errorMessage.push('Call period length value must be a valid number greater than 0');
            }
          }
          if (this.get('controller.enforcedOnDuration') === '' || this.get('controller.enforcedOnDuration') === null) {
            dataIsGood = false;
            errorMessage.push('Missing enforced on duration');
          } else {
            var _value3 = parseInt(this.get('controller.enforcedOnDuration'));
            if (_value3 <= 0 || isNaN(_value3)) {
              dataIsGood = false;
              errorMessage.push('Enforced on duration value must be a valid number greater than 0');
            }
          }
        }

        var validationArrayRsp = (0, _route.validateAttributesLocalized)(this.get('controller.attributesLocalized'));
        if (!validationArrayRsp[0]) {
          dataIsGood = false;
          errorMessage.push.apply(errorMessage, _toConsumableArray(validationArrayRsp[1]));
        }

        if (dataIsGood) {
          console.log('data is validated and good');
          this.get('platform').doPost(['access', 'product'].join('/'), {
            class: this.get('controller.class'),
            key: this.get('controller.key').toUpperCase(),
            entireCall: this.get('controller.entireCall'),
            termsAndConditionsUrl: this.get('controller.termsAndConditionsUrl'),
            type: "PUBLIC", // always public for us for now
            enabled: this.get('controller.enabled'),
            availableToGuests: this.get('controller.availableToGuests'),
            enforcedOnExplorers: this.get('controller.enforcedOnExplorers'),
            termsAndConditions: this.get('controller.termsAndConditions'),
            effectiveTo: this.get('controller.effectiveTo') ? (0, _changePSTToISO.changePSTtoISO)([this.get('controller.effectiveTo')]) : null,
            expired: this.get('controller.expired'),
            agentMessage: this.get('controller.agentMessage'),
            visible: this.get('controller.visible'),
            requireAgentApproval: this.get('controller.requireAgentApproval'),
            callPeriodLength: this.get('controller.callFrequencyCheckbox') ? parseInt(this.get('controller.callPeriodLength')) : 3600,
            enforcedOnDuration: this.get('controller.callFrequencyCheckbox') ? parseInt(this.get('controller.enforcedOnDuration')) : 60,
            callsPerPeriod: this.get('controller.callFrequencyCheckbox') ? parseInt(this.get('controller.callsPerPeriod')) : -1,
            sticky: this.get('controller.sticky'),
            durationPerCall: this.get('controller.callLimitCheckbox') ? parseInt(this.get('controller.durationPerCall')) : -1,
            effectiveFrom: this.get('controller.effectiveFrom') ? (0, _changePSTToISO.changePSTtoISO)([this.get('controller.effectiveFrom')]) : null,
            durationAllowed: this.get('controller.durationAllowed') ? parseInt(this.get('controller.durationAllowed')) : -1,
            account: {
              id: accountId
            },
            attributesLocalized: (0, _route.removeNotSetLocalizations)(this.get('controller.attributesLocalized'))
          }).then(function () {
            console.log('platform submitted');
            _this.get('notify').success('Product/Promotion created!');
            _this.transitionTo('accounts.account.products');
          }, function (error) {
            _this.send('error', error.errorMessage);
          });
          this.set('controller.errorMessage', null);
        } else {
          this.set('controller.errorMessage', errorMessage);
        }
      }
    }
  });
});