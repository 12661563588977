define('aira-mgmt/users/user/credit/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    minutesToCredit: null,

    internalReason: null,

    externalReason: null,

    salesforceCaseNumber: null,

    actions: {
      selectInternalReason: function selectInternalReason(content) {
        this.set('internalReason', content);
      },
      selectExternalReason: function selectExternalReason(content) {
        this.set('externalReason', content);
      }
    }
  });
});