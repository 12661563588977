define('aira-mgmt/apps/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		model: function model() {
			return this.get('store').query('app', { filter: { 'requestSource': 'ALL' } });
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			controller.set('activeId', -1);
		},


		actions: {
			selectApp: function selectApp(app) {
				this.transitionTo('apps.app', app);
			}
		}
	});
});