define('aira-mgmt/users/user/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    notify: Ember.inject.service(),

    beforeModel: function beforeModel() {},


    actions: {
      save: function save(profile, profileProps) {
        var _this = this;

        profile.setProperties(profileProps);
        return profile.save().then(function () {
          _this.set('state.isDirty', false);
          _this.get('notify').success('Profile was updated.');
        }, function (error) {
          _this.send('error', error);
          _this.modelFor('users.user').rollbackAttributes();
        });
      }
    }
  });
});