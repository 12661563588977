define('aira-mgmt/adapters/app', ['exports', 'aira-mgmt/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({

		platform: Ember.inject.service(),

		query: function query(store, type, _query) {
			var url = 'app/version?requestSource=' + _query.filter.requestSource;
			return this.platform.doGet(url);
		},
		urlForQuery: function urlForQuery() {
			return [this.host, this.namespace, 'app', 'version'].join('/');
		},
		urlForUpdateRecord: function urlForUpdateRecord() {
			return [this.host, this.namespace, 'app', 'version'].join('/');
		}
	});
});