define('aira-mgmt/users/user/subscription/upgrade/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('newPlan', null);
      controller.set('inProgress', true);
      controller.set('userId', this.modelFor('users.user').get('id'));
    },


    actions: {
      cancel: function cancel() {
        this.transitionTo('users.user.subscription');
      },
      save: function save(newPlan) {
        var _this = this;

        if (newPlan == null) {
          return;
        }

        this.set('controller.inProgress', true);
        var billingInfo = this.get('controller.billingInfo');
        var cvv = prompt("Please provide the CVV for the " + billingInfo.card_type + " ending in " + billingInfo.last_four);
        var isError = false;

        if (cvv === null) {
          isError = true;
        } else if (isNaN(cvv)) {
          this.get('notify').error('CVV contains non-number characters');
          isError = true;
        } else if (cvv.length !== 3 && cvv.length !== 4) {
          this.get('notify').error('CVV number is the incorrect length');
          isError = true;
        }
        if (isError) {
          this.set('controller.inProgress', false);
        } else {
          this.get('platform').doPut('care/order/' + this.get('controller.userId') + '/primary', {
            "orderlines": [{
              "productCode": newPlan,
              "quantity": 1,
              "productType": "primary"
            }],
            "cvv": cvv
          }).then(function (response) {
            _this.set('controller.inProgress', false);
            _this.get('notify').success('Subscription Successfully Changed!');
            _this.transitionTo('users.user');
          }, function (error) {
            _this.set('controller.inProgress', false);
            _this.send('error', error);
          });
        }
      }
    }
  });
});