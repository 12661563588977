define('aira-mgmt/users/user/contacts/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),
    contacts: Ember.computed.alias('state.users.user.contacts'),

    notify: Ember.inject.service(),

    model: function model(p) {
      return this.get('store').findRecord('contact', p.contact_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // applies row highlight on transition from row click
      this.set('contacts.selectedContactId', model.id);
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
          transition.abort();
        } else {
          this.set('contacts.selectedContactId', -1);
        }
      },

      save: function save(contact, contactProps) {
        var _this = this;

        contact.setProperties(contactProps);
        return contact.save().then(function () {
          _this.set('state.isDirty', false);
          _this.get('notify').success('Contact was updated.');
          _this.transitionTo('users.user.contacts');
        }, function (error) {
          _this.send('error', error);
        });
      },
      cancel: function cancel(contact) {
        contact.rollbackAttributes();
        this.transitionTo('users.user.contacts');
      },
      delete: function _delete(contact) {
        var _this2 = this;

        contact.destroyRecord().then(function () {
          _this2.set('state.isDirty', false);
          _this2.get('notify').success('Contact was deleted.');
          _this2.transitionTo('users.user.contacts');
        });
      }
    }
  });
});