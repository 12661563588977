define('aira-mgmt/serializers/app', ['exports', 'aira-mgmt/serializers/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
			return this._super(store, primaryModelClass, payload.versions, id, requestType);
		}
	});
});