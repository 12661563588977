define('aira-mgmt/users/user/credit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    session: Ember.inject.service('session'),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('minutesToCredit', null);
      controller.set('internalReason', null);
      controller.set('externalReason', null);
      controller.set('salesforceCaseNumber', null);
    },

    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        history: new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('platform').doGet('credit/history?userId=' + _this.modelFor('users.user').id).then(function (response) {
            var list = [];

            response.payload.reverse().forEach(function (credit) {
              credit['minutes'] = credit['seconds'] / 60;
              list.push(Ember.Object.create(credit));
            });

            resolve(list);
          }, function (_ref) {
            var errorCode = _ref.errorCode,
                errorMessage = _ref.errorMessage;

            reject({ errorCode: errorCode, errorMessage: errorMessage });
          });
        }),
        internalReasons: new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('platform').doGet('credit/internal').then(function (response) {
            resolve(response.payload.map(function (item) {
              return {
                id: item.reason,
                text: item.reason
              };
            }));
          }, function (_ref2) {
            var errorCode = _ref2.errorCode,
                errorMessage = _ref2.errorMessage;

            reject({ errorCode: errorCode, errorMessage: errorMessage });
          });
        }),
        externalReasons: new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('platform').doGet('credit/external').then(function (response) {
            resolve(response.payload.map(function (item) {
              return {
                id: item.reason,
                text: item.reason
              };
            }));
          }, function (_ref3) {
            var errorCode = _ref3.errorCode,
                errorMessage = _ref3.errorMessage;

            reject({ errorCode: errorCode, errorMessage: errorMessage });
          });
        })
      });
    },


    actions: {
      credit: function credit() {
        var _this2 = this;

        var userId = this.modelFor('users.user').get('id');

        var minutesToCredit = this.get('controller.minutesToCredit');
        var internalReason = this.get('controller.internalReason');
        var externalReason = this.get('controller.externalReason');
        var salesforceCaseNumber = this.get('controller.salesforceCaseNumber');
        if (minutesToCredit && $.isNumeric(minutesToCredit) && internalReason && externalReason) {
          this.get('platform').doPost(['mgmt', userId, 'credit'].join('/'), {
            minutes: minutesToCredit,
            internalReason: internalReason,
            externalReason: externalReason,
            salesforceCaseNumber: salesforceCaseNumber
          }).then(function () {
            _this2.get('notify').success('Minutes were credited!');
            _this2.transitionTo('users.user');
          }, function (error) {
            _this2.send('error', error);
          });
        }
      }
    }
  });
});