define('aira-mgmt/users/user/wifi/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),
    wifi: Ember.computed.alias('state.users.user.wifi'),

    notify: Ember.inject.service(),

    model: function model() {
      this.set('wifi.selectedWifiProfileId', -1);

      var userId = this.modelFor('users.user').get('id');
      var login = this.modelFor('users.user').get('login');

      return this.get('store').createRecord('wifi', {
        username: login,
        priority: 0,
        ssid: null,
        securityType: 'WPA2',
        secretKey: null,
        type: 'WIFI',
        userId: userId
      });
    },
    deactivate: function deactivate() {
      // Not done in cancel incase user navigates away by another means
      this.get('currentModel').rollbackAttributes();
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
          transition.abort();
        }
      },

      save: function save(profile, wifiProps) {
        var _this = this;

        profile.setProperties(wifiProps);
        profile.save().then(function () {
          _this.set('state.isDirty', false);
          _this.send('newWifiProfile', _this.get('currentModel'));
          _this.get('notify').success('Wifi profile was added.');
          _this.transitionTo('users.user.wifi');
        });
      },
      cancel: function cancel(profile) {
        console.log(profile);
        this.transitionTo('users.user.wifi');
      }
    }
  });
});