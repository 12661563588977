define('aira-mgmt/onboard/wifi/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model(p) {
			// Because wifi profiles can't be queries on userId right now.
			return new Ember.RSVP.Promise(function (resolve, reject) {
				var _this = this;

				// Find user first
				this.get('store').findRecord('user', p.user_id).then(function (user) {
					console.log(user);
					// Use login of found user to find wifi profiles
					resolve(Ember.RSVP.hash({
						user: user,
						profiles: _this.get('store').query('wifi', { filter: { userId: p.user_id } })
					}));
				}, reject);
			}.bind(this));
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			// Provide state service to generated controller, avoids making our own.
			//controller.set('state', this.get('state'));
			this.send('updateSubtitle', 'Wifi Profiles');
			controller.set('nextDisabled', false);
			controller.set('helpEnabled', true);
		},


		actions: {
			addWifiProfile: function addWifiProfile() {
				this.transitionTo('onboard.wifi.new');
			},
			toggleNext: function toggleNext(shouldEnable) {
				// Reversed to make template easier
				this.set('controller.nextDisabled', !shouldEnable);
			},
			toggleHelp: function toggleHelp(shouldEnable) {
				this.set('controller.helpEnabled', shouldEnable);
			},


			// received from wifi/new route. Need to reload all profiles because API
			// does not return the id of the newly created profile.
			newWifiProfile: function newWifiProfile() {
				this.get('currentModel.profiles').update();
			},
			back: function back() {
				this.transitionTo('onboard.google', this.get('currentModel.user.id'));
			},
			next: function next() {
				this.transitionTo('onboard.interview', this.get('currentModel.user.id'));
			}
		}
	});
});