define('aira-mgmt/accounts/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },


    actions: {
      addAccountCancel: function addAccountCancel() {
        this.transitionTo('accounts');
      },
      addAccount: function addAccount() {
        var _this = this;

        //check valid inputs
        var dataIsGood = true;
        var errorMessage = [];

        if (this.get('controller.accountName') === '' || this.get('controller.accountName') === undefined) {
          dataIsGood = false;
          errorMessage.push('Missing account name');
        }
        if (this.get('controller.firstName') === '' || this.get('controller.firstName') === undefined) {
          dataIsGood = false;
          errorMessage.push('Missing first name');
        }
        if (this.get('controller.lastName') === '' || this.get('controller.lastName') === undefined) {
          dataIsGood = false;
          errorMessage.push('Missing last name');
        }
        if (this.get('controller.gender') === '' || this.get('controller.gender') === null) {
          dataIsGood = false;
          errorMessage.push('Missing gender selection');
        }
        if (this.get('controller.email') === '' || this.get('controller.email') === undefined || !this.get('controller.email').includes('@')) {
          dataIsGood = false;
          errorMessage.push('Missing email or correct @ email address');
        }

        if (dataIsGood) {
          this.get('platform').doPost('order/business', {
            account: {
              name: this.get('controller.accountName'),
              firstname: this.get('controller.firstName'),
              lastname: this.get('controller.lastName'),
              acceptBusinessUsers: this.get('controller.acceptBusinessUsers'),
              gender: this.get('controller.gender'),
              email: this.get('controller.email')
            },
            paymentInfo: {
              type: "manual"
            },
            orderlines: [{
              productCode: "site_access_service",
              quantity: 1,
              productType: "primary"
            }, {
              productCode: "sa_brick_2500",
              quantity: 1,
              productType: "fixed"
            }],
            operatingCountry: "US"
          }).then(function () {
            console.log('new account submitted');
            _this.get('notify').success('Account created!');
            _this.transitionTo('accounts');
          }, function (error) {
            _this.send('error', error.errorMessage);
          });
          this.set('controller.errorMessage', null);
        } else {
          this.set('controller.errorMessage', errorMessage);
        }
      }
    }
  });
});