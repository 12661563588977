define('aira-mgmt/serializers/user', ['exports', 'aira-mgmt/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {

		attrs: {
			contacts: { embedded: 'always' },
			addresses: { embedded: 'always' },
			appRoles: { embedded: 'always' }
		},

		/**
   * The search API returns an old style User so we reformat the payload here
      */
		normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
			var users = payload.users;
			var i = 0;
			var l = users.length;
			for (; i < l; i++) {
				// dateofbirth -> dateOfBirth
				users[i].dateOfBirth = users[i].dateofbirth;
			}
			return this._super(store, primaryModelClass, users, id, requestType);
		},
		normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, primaryModelClass, payload, id, requestType) {
			delete payload.response;
			return this._super(store, primaryModelClass, payload, id, requestType);
		},
		normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
			payload.id = payload.userid;
			payload.dateOfBirth = payload.dateofbirth;
			payload.approles = payload.appRoles;

			return { data: payload };
		},
		serialize: function serialize(snapshot, options) {
			var payload = this._super(snapshot, options);

			delete payload.addresses;
			delete payload.contacts;

			if (snapshot.record.get('isNew')) {
				payload['dateofbirth'] = payload['dateOfBirth'];
				delete payload.dateOfBirth;

				// hack to lowercase approles and appname when using the create user api.
				var approles = [];
				var appRoles = payload.appRoles;
				for (var i = 0, l = appRoles.length; i < l; i++) {
					approles.push({
						appname: appRoles[i].appName,
						roles: appRoles[i].roles
					});
				}
				payload['approles'] = approles;
				delete payload.appRoles;
			}

			return payload;
		}
	});
});