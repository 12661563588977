define('aira-mgmt/users/user/access/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    platform: Ember.inject.service(),

    notify: Ember.inject.service(),

    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        access: new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('platform').doGet('mgmt/' + _this.modelFor('users.user').id + '/access/default').then(function (response) {
            response.userId = parseInt(_this.modelFor('users.user').id);
            console.log('response data with userID', response);
            resolve(response);
          }, function (_ref) {
            var errorCode = _ref.errorCode,
                errorMessage = _ref.errorMessage;

            reject({ errorCode: errorCode, errorMessage: errorMessage });
            return [];
          });
        })
      });
    }
  });
});