define('aira-mgmt/apps/app/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		notify: Ember.inject.service(),

		actionOptions: [{ id: 'upsert', text: 'upsert' }, { id: 'forceUpsert', text: 'forceUpsert' }, { id: 'delete', text: 'delete' }],

		changeset: null,

		actions: {
			selectAction: function selectAction(action) {
				this.changeset.set('action', action);
			},
			cancel: function cancel() {
				this.changeset.rollback();
				this.transitionToRoute('apps');
			},
			save: function save() {
				var _this = this;

				return this.changeset.save().then(function () {
					_this.get('notify').success('App Version was updated.');
					_this.transitionToRoute('apps');
				}, function (error) {
					_this.send('error', error);
				});
			}
		}
	});
});