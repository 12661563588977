define('aira-mgmt/users/user/service/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['pg'],
    notify: Ember.inject.service(),
    platform: Ember.inject.service(),
    router: Ember.inject.service(),

    /**
     * This is set either by:
     * 1) the #link-to in the "user" template
     * 2) Directly from the URL
     * 3) The setup method in the route
     */
    pg: null,

    timezone: null,

    disableBack: Ember.computed(function () {
      return parseInt(this.get('pg')) <= 0;
    }).property('pg'),

    disableNext: Ember.computed(function () {
      return this.get('model.service.response.hasMore') === false;
    }).property('pg'),

    actions: {
      previousPage: function previousPage() {
        this.decrementProperty('pg');
      },
      nextPage: function nextPage() {
        this.incrementProperty('pg');
      },
      editShareSession: function editShareSession(service) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.model.service.payload[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var s = _step.value;

            if (s.id === service.id) {
              Ember.set(s, 'isEditShareSession', true);
            } else {
              Ember.set(s, 'isEditShareSession', false);
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      },
      cancelShareSession: function cancelShareSession(service) {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = this.model.service.payload[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var s = _step2.value;

            if (s.id === service.id) {
              Ember.set(s, 'shareSession', !s.shareSession);
              Ember.set(s, 'isEditShareSession', false);
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      },
      saveChangeShareSession: function saveChangeShareSession(service) {
        var _this = this;

        this.platform.doPut('mgmt/service-request/' + service.id + '/build-ai/allow-sharing', {
          value: !service.buildAi.explorerAllowSharing
        }).then(function () {
          _this.notify.success('Updated share for service with ID ' + service.id + '.');
          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = _this.model.service.payload[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var s = _step3.value;

              if (s.id === service.id) {
                Ember.set(s, 'isEditShareSession', false);
              }
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }

          _this.send('forceRefreshUserService');
        }, function (_ref) {
          var errorMessage = _ref.errorMessage;

          _this.notify.error(errorMessage);
        });
      }
    }
  });
});