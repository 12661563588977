define('aira-mgmt/components/user-contact/component', ['exports', 'aira-mgmt/mixins/model-editor'], function (exports, _modelEditor) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var contactPropNames = ['firstName', 'lastName', 'email', 'phone', 'relationship'];

	exports.default = Ember.Component.extend(_modelEditor.default, {

		state: Ember.inject.service(),

		title: null,

		contact: null,

		onSave: null,

		onCancel: null,

		onDelete: null,

		data: null,

		init: function init() {
			this._super.apply(this, arguments);
			this._bindModelEditor('data', 'contact', contactPropNames);
		},


		actions: {
			selectRelationship: function selectRelationship(relationship) {
				this.set('data.relationship', relationship);
			},
			save: function save() {
				var contactProps = this.get('data').getProperties(contactPropNames);
				this.get('onSave')(this.get('contact'), contactProps);
			},
			cancel: function cancel() {
				this.set('state.isDirty', false);
				this.get('onCancel')(this.get('contact'));
			},
			delete: function _delete() {
				if (confirm('Are you sure?')) {
					this.get('onDelete')(this.get('contact'));
				}
			}
		},

		relationshipOptions: [{ text: 'Spouse', id: 'Spouse' }, { text: 'Parent', id: 'Parent' }, { text: 'Child', id: 'Child' }, { text: 'Sibling', id: 'Sibling' }, { text: 'Partner', id: 'Partner' }, { text: 'Friend', id: 'Friend' }, { text: 'Other', id: 'Other' }]
	});
});