define('aira-mgmt/components/properties/property-display/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },
    getShortenedKey: function getShortenedKey(key) {
      return key.substring(key.lastIndexOf('.') + 1).toString();
    }
  });
});