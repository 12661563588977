define('aira-mgmt/users/user/wifi/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),
    wifi: Ember.computed.alias('state.users.user.wifi'),

    notify: Ember.inject.service(),

    model: function model(p) {
      // Ugly, but currently cannot query wifi profiles by id.
      // This find the correct wifi profile in the array of profiles in our parent route's model
      return this.modelFor('users.user.wifi').profiles.findBy('id', p.wifi_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // applies row highlight on transition from row click
      this.set('wifi.selectedWifiProfileId', model.id);
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
          transition.abort();
        } else {
          this.set('wifi.selectedWifiProfileId', -1);
        }
      },

      save: function save(profile, wifiProps) {
        var _this = this;

        profile.setProperties(wifiProps);
        return profile.save().then(function () {
          _this.set('state.isDirty', false);
          _this.get('notify').success('Wifi profile was updated.');
          _this.transitionTo('users.user.wifi');
        });
      },
      cancel: function cancel(profile) {
        profile.rollbackAttributes();
        this.transitionTo('users.user.wifi');
      },
      delete: function _delete(profile) {
        var _this2 = this;

        profile.destroyRecord().then(function () {
          _this2.set('state.isDirty', false);
          _this2.get('notify').success('Wifi profile was deleted.');
          _this2.transitionTo('users.user.wifi');
        });
      }
    }
  });
});