define('aira-mgmt/accounts/account/users/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    beforeModel: function beforeModel() {},
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        primary: new Ember.RSVP.Promise(function (resolve, reject) {
          var accountId = _this.modelFor('accounts.account').account.accountId;
          var accountType = "PRIMARY";
          _this.get('platform').doGet('mgmt/account/' + accountId + '/' + accountType).then(function (response) {
            resolve(response.payload);
          }, function (_ref) {
            var errorCode = _ref.errorCode,
                errorMessage = _ref.errorMessage;

            reject({ errorCode: errorCode, errorMessage: errorMessage });
          });
        }),
        users: new Ember.RSVP.Promise(function (resolve, reject) {
          var accountId = _this.modelFor('accounts.account').account.accountId;
          var accountType = _this.modelFor('accounts.account').account.accountType;
          var accountToSearchFor = null;
          if (accountType === 'INDIVIDUAL') {
            accountToSearchFor = 'SECONDARY';
          } else if (accountType === 'BUSINESS') {
            accountToSearchFor = 'BUSINESS';
          }

          if (accountToSearchFor !== null) {
            _this.get('platform').doGet('mgmt/account/' + accountId + '/' + accountToSearchFor).then(function (response) {
              var users = response.payload;
              var _iteratorNormalCompletion = true;
              var _didIteratorError = false;
              var _iteratorError = undefined;

              try {
                for (var _iterator = users[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                  var u = _step.value;

                  u.isEditing = false;
                  u.isEditingEndDate = false;
                  if (u.effectiveTo !== null) {
                    // Only show the date part of the effectiveTo
                    u.effectiveTo = u.effectiveTo.split("T")[0];
                  }
                }
              } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion && _iterator.return) {
                    _iterator.return();
                  }
                } finally {
                  if (_didIteratorError) {
                    throw _iteratorError;
                  }
                }
              }

              resolve(users);
            }, function (_ref2) {
              var errorCode = _ref2.errorCode,
                  errorMessage = _ref2.errorMessage;

              reject({ errorCode: errorCode, errorMessage: errorMessage });
            });
          } else {
            resolve([]);
          }
        }),
        individualAccount: this.modelFor('accounts.account').account.accountType === 'INDIVIDUAL',
        businessAccount: this.modelFor('accounts.account').account.accountType === 'BUSINESS'
      });
    },
    setupController: function setupController(controller, model) {
      var _this2 = this;

      this._super(controller, model);
      console.log("printing out the model", model.invites);
      controller.set('addUserID', null);
      controller.set('addUserDurationAllowed', null);
      controller.set('deleteUserID', null);
      controller.set('inviteUserEmail', null);
      controller.set('deleteInviteUserEmail', null);
      controller.set('deleteSecondaryUserID', null);
      controller.set('primaryUserID', model.primary[0].userId);

      if (model.individualAccount) {
        new Ember.RSVP.Promise(function (resolve, reject) {
          _this2.get('platform').doGet('account/sharing/' + model.primary[0].userId).then(function (response) {
            console.log("printing response in controller", response);
            controller.set('invites', response.invites);
            resolve(response.invites);
          }, function (_ref3) {
            var errorCode = _ref3.errorCode,
                errorMessage = _ref3.errorMessage;

            reject({ errorCode: errorCode, errorMessage: errorMessage });
          });
        });
      }

      console.log("printing out the controller invites ", this.controller.get('invites'));
    },


    actions: {
      selectUserAccount: function selectUserAccount(accountId, userId) {
        console.log("Selected user :" + userId + ", in account: " + accountId);
        this.controller.set('activeId', userId);
        this.transitionTo('users.user', userId);
      },
      selectUserInvite: function selectUserInvite(inviteeEmail) {
        console.log("in selectUserInvite invitee email is :", inviteeEmail);
        this.controller.set('inviteeActiveId', inviteeEmail);
      },
      addUserToBusinessAccount: function addUserToBusinessAccount() {
        var _this3 = this;

        this.controller.set('addingUser', true);
        var accountId = this.modelFor('accounts.account').account.accountId;

        var userId = this.controller.get('addUserID');
        if (!userId || parseInt(userId) < 1) {
          this.get('notify').error('Invalid user ID.');
          this.controller.set('addingUser', false);
          return;
        }

        var durationAllowed = -1;
        if (this.controller.get('addUserDurationAllowed')) {
          if (parseInt(this.controller.get('addUserDurationAllowed')) < 1) {
            this.get('notify').error('Minutes allowed must be greater than 0.');
            return;
          }

          // Convert minutes to seconds
          durationAllowed = parseInt(this.controller.get('addUserDurationAllowed')) * 60;
        }

        var endpoint = 'mgmt/account/' + accountId + '/user/' + userId + '/business?durationAllowed=' + durationAllowed;

        this.get('platform').doPost(endpoint).then(function () {
          _this3.get('notify').success('Added user with ID ' + userId + ' to account ' + accountId + '.');
          _this3.refresh();
        }, function (_ref4) {
          var errorMessage = _ref4.errorMessage;

          _this3.get('notify').error(errorMessage);
        }).finally(function () {
          _this3.controller.set('addingUser', false);
        });
      },
      deleteUserFromBusinessAccount: function deleteUserFromBusinessAccount() {
        var _this4 = this;

        var accountId = this.modelFor('accounts.account').account.accountId;
        var userId = this.controller.get("deleteUserID");
        if (userId) {
          this.get('platform').doDelete('mgmt/account/' + accountId + '/user/' + userId + '/business').then(function (response) {
            _this4.get('notify').success('Delete user with ID ' + userId + ' from account ' + accountId);
            _this4.refresh();
          }, function (_ref5) {
            var errorCode = _ref5.errorCode,
                errorMessage = _ref5.errorMessage;

            _this4.get('notify').error(errorMessage);
          });
        } else {
          this.get('notify').error("Please input a valid number to delete a business user ");
        }
      },
      inviteSecondaryUser: function inviteSecondaryUser() {
        var _this5 = this;

        var primaryID = this.controller.get("primaryUserID");
        var inviteeEmail = this.controller.get("inviteUserEmail");
        if (inviteeEmail && primaryID) {
          this.get('platform').doPost('account/sharing/invite', {
            userId: primaryID,
            invitee: inviteeEmail
          }).then(function (response) {
            _this5.get('notify').success('Invited user with email ' + inviteeEmail + ' to account ');
            _this5.refresh();
          }, function (_ref6) {
            var errorCode = _ref6.errorCode,
                errorMessage = _ref6.errorMessage;

            _this5.get('notify').error(errorMessage);
          });
        } else {
          this.get('notify').error("Primary User ID or Invitee Email is invalid, please try again");
        }
        console.log("Inviting secondary users ", inviteeEmail);
      },
      deleteInviteSecondaryUser: function deleteInviteSecondaryUser() {
        var _this6 = this;

        var inviteeEmail = this.controller.get("deleteInviteUserEmail");
        var primaryID = this.controller.get("primaryUserID");
        console.log(primaryID);
        console.log(inviteeEmail);

        if (inviteeEmail && primaryID) {
          this.get('platform').doDelete('account/sharing/invite', {
            userId: primaryID,
            invitee: inviteeEmail
          }).then(function (response) {
            _this6.get('notify').success('Deleted user with email ' + inviteeEmail + ' from account ');
            _this6.refresh();
          }, function (_ref7) {
            var errorCode = _ref7.errorCode,
                errorMessage = _ref7.errorMessage;

            _this6.get('notify').error(errorMessage);
          });
        } else {
          this.get('notify').error("Primary User ID or Invitee Email is invalid, please try again");
        }
        console.log("Deleting invitation of secondary users ", inviteeEmail);
      },
      deleteSecondaryUser: function deleteSecondaryUser() {
        var _this7 = this;

        var userID = this.controller.get("deleteSecondaryUserID");

        if (userID) {
          this.get('platform').doDelete('account/sharing/' + userID).then(function (response) {
            _this7.get('notify').success('Deleted user with email ' + userID + ' from account ');
            _this7.refresh();
          }, function (_ref8) {
            var errorCode = _ref8.errorCode,
                errorMessage = _ref8.errorMessage;

            _this7.get('notify').error(errorMessage);
          });
        } else {
          this.get('notify').error("UserID is not valid, please try again");
        }

        console.log("Deleting secondary users ", userID);
      }
    }
  });
});