define('aira-mgmt/users/user/subscription/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var planUpgradesDisabled = model.orderOptions.getWithDefault('primaryPlans', []).length === 0;
      var canModifyPlan = model.usage.getWithDefault('canModifyPlan', true);
      controller.set('planUpgradesDisabled', planUpgradesDisabled || !canModifyPlan);

      var addonMinutesDisabled = model.orderOptions.getWithDefault('minuteAddOnAdjustments', []).length === 0;

      var canPurchaseMinutes = model.usage.get('canPurchaseMinutes');

      controller.set('addonMinutesDisabled', addonMinutesDisabled || !canPurchaseMinutes);
    }
  });
});