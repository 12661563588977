define('aira-mgmt/helpers/shorten-key', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.shortenKey = shortenKey;
  function shortenKey(key) {
    var result = key.toString();
    return result.substring(result.lastIndexOf('.') + 1);
  }

  exports.default = Ember.Helper.helper(shortenKey);
});