define('aira-mgmt/accounts/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    queryParams: {
      q: { refreshModel: true },
      type: { refreshModel: true }
    },

    state: Ember.inject.service(),
    notify: Ember.inject.service(),
    platform: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      console.log(params);
      if (params.q && params.type) {
        return Ember.RSVP.hash({
          account: new Ember.RSVP.Promise(function (resolve, reject) {
            _this.get('platform').doGet('search/account?type=' + encodeURIComponent(params.type) + '&q=' + encodeURIComponent(params.q)).then(function (response) {
              resolve(response.users);
            }, function (_ref) {
              var errorCode = _ref.errorCode,
                  errorMessage = _ref.errorMessage;

              reject({ errorCode: errorCode, errorMessage: errorMessage });
              return [];
            });
          })
        });
      } else {
        return [];
      }
    },
    afterModel: function afterModel(accounts) {
      if (accounts.account) {
        this.set('state.ariaLive', accounts.account.length + ' results.');
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('users', this.get('users'));
      controller.set('search', controller.get('q'));
      controller.set('accountType', "");
      if (controller.get('q') !== null && model.length < 1) {
        this.get('notify').error('No Account was found');
      }
    },


    actions: {
      addNewAccount: function addNewAccount() {
        console.log('add new account pressed');
        this.transitionTo('accounts.new');
      },
      loading: function loading(transition) {
        var controller = this.get('controller');
        if (controller) {
          controller.set('isSearching', true);
          transition.promise.finally(function () {
            controller.set('isSearching', false);
          });
        }
      },
      searchByAccountName: function searchByAccountName() {
        console.log('search by account name pressed');
        var searchType = "ACCOUNT_NAME";
        var query = "?";

        //Checks if the first name search bar has a non-empty value
        if (this.get("controller.accountName")) {
          query = this.get("controller.accountName");
          //if it has a valid value reassign firstName variable
        }
        this.transitionTo('accounts', {
          queryParams: {
            q: query,
            type: searchType
          }
        });
      },
      searchByAccountCode: function searchByAccountCode() {
        console.log('search by account code pressed');
        var searchType = "ACCOUNT_CODE";
        var query = "?";

        //Checks if the first name search bar has a non-empty value
        if (this.get("controller.accountCode")) {
          query = this.get("controller.accountCode");
          //if it has a valid value reassign firstName variable
        }

        this.transitionTo('accounts', {
          queryParams: {
            q: query,
            type: searchType
          }
        });
      },
      searchByAccountType: function searchByAccountType() {
        console.log('search by account type pressed');
        var searchType = "ACCOUNT_TYPE";
        var acctType = this.get('controller').accountType;

        this.transitionTo('accounts', {
          queryParams: {
            q: acctType,
            type: searchType
          }
        });
      },
      searchByAccountID: function searchByAccountID() {
        console.log('search by account id pressed');
        var searchType = "ACCOUNT_ID";
        var query = "?";
        if (this.get("controller.accountId")) {
          query = this.get("controller.accountId");
          //if it has a valid value reassign firstName variable
        }
        this.transitionTo('accounts', {
          queryParams: {
            q: query,
            type: searchType
          }
        });
      },
      searchByAccountSiteName: function searchByAccountSiteName() {

        var accountSiteNameQuery = this.get("controller.accountSiteName");
        console.log('search by account site name pressed, account site name query: ' + accountSiteNameQuery);
        if (accountSiteNameQuery) {
          this.transitionTo('accounts', {
            queryParams: {
              q: accountSiteNameQuery,
              type: "ACCOUNT_SITE_NAME"
            }
          });
        }
      },
      searchByAccountSiteAddress: function searchByAccountSiteAddress() {

        var accountSiteAddressQuery = this.get("controller.accountSiteAddress");
        console.log('search by account site address pressed, account site address query: ' + accountSiteAddressQuery);
        if (accountSiteAddressQuery) {
          this.transitionTo('accounts', {
            queryParams: {
              q: accountSiteAddressQuery,
              type: "ACCOUNT_SITE_ADDRESS"
            }
          });
        }
      },
      select: function select(account) {
        this.controller.set('activeId', account.accountId);
        try {
          var activeTab = this.controllerFor('accounts.account').get('activeTab');
          // Don't pass whole model because it's currently incomplete.
          // This will trigger a call to the api that fetches the whole model
          this.transitionTo('accounts.account.' + activeTab, account.accountId);
        } catch (e) {
          // Just means no user had been selected yet so there's
          // no controller initialized so there's no activeTab to maintain.
          // Don't pass whole model because it's currently incomplete.
          // This will trigger a call to the api that fetches the whole model
          console.log("Trying to go to accounts but caught something ", e);
          this.transitionTo('accounts.account', account.accountId);
        }
      },


      /**
       *
       * @param {String} searchType
       * This method takes a string when it's called by one of the toolbar buttons
       * That String will denote which search bars we want to display
       */
      displaySearch: function displaySearch(searchType) {
        var setOfSearchDisplays = new Set();
        setOfSearchDisplays.add(document.getElementById("searchAccountNames"));
        setOfSearchDisplays.add(document.getElementById("searchByAccountID"));
        setOfSearchDisplays.add(document.getElementById("searchByAccountCode"));
        setOfSearchDisplays.add(document.getElementById("searchByAccountType"));
        setOfSearchDisplays.add(document.getElementById("searchByAccountSiteName"));
        setOfSearchDisplays.add(document.getElementById("searchByAccountSiteAddress"));

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = setOfSearchDisplays[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var searchDisplay = _step.value;

            if (searchType === searchDisplay.id) {
              searchDisplay.style.display = "block";
            } else {
              searchDisplay.style.display = "none";
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
    }
  });
});