define('aira-mgmt/users/user/subscription/route', ['exports', 'aira-mgmt/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    queryParams: {
      // HACK: Because I'm an Ember noob, I don't know of a better way to forcibly refresh the model on a transition.
      refreshModel: {
        refreshModel: true
      }
    },

    model: function model() {
      var _this = this;

      var recurlyUrlPrefix = _environment.default.APP.env === 'prod' ? "https://aira.recurly.com/accounts/" : "https://aira-" + _environment.default.APP.env + ".recurly.com/accounts/";

      return Ember.RSVP.hash({
        // API Call #1 - Subscription details
        billing: new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('platform').doGet('subscription?userId=' + _this.modelFor('users.user').id).then(function (subscription) {
            resolve(Ember.Object.create({
              noAccount: false,
              noSubscription: false,
              subscription: subscription.subscription,
              name: subscription.name,
              planCode: subscription.planCode,
              showActivateButton: subscription.subscription == null || subscription.subscription.state !== 'active',
              primaryUserId: subscription.primaryUserId,
              accountType: subscription.accountType,
              recurlyUrlPrefix: recurlyUrlPrefix
            }));
          }, function (_ref) {
            var errorCode = _ref.errorCode,
                errorMessage = _ref.errorMessage;

            if (errorCode === 'KN-ACC-002') {
              resolve(Ember.Object.create({
                noAccount: true,
                isPrimary: false,
                isSecondary: false,
                isBusiness: false,
                noSubscription: true,
                recurlyUrlPrefix: recurlyUrlPrefix,
                accountCode: _this.modelFor('users.user').accountCode
              }));
            } else if (errorCode === 'BIZ-SUB-002') {
              console.log(_this.modelFor('users.user'));
              resolve(Ember.Object.create({
                noAccount: false,
                isPrimary: _this.modelFor('users.user').accounts.find(function (acc) {
                  return acc.userType === 'primary';
                }),
                isSecondary: _this.modelFor('users.user').accounts.find(function (acc) {
                  return acc.userType === 'secondary';
                }),
                isBusiness: _this.modelFor('users.user').accounts.find(function (acc) {
                  return acc.userType === 'business';
                }),
                noSubscription: true,
                recurlyUrlPrefix: recurlyUrlPrefix,
                accountCode: _this.modelFor('users.user').accountCode
              }));
            } else {
              reject({ errorCode: errorCode, errorMessage: errorMessage });
            }
          });
        }),
        // API Call #2 - Usage details
        usage: new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('platform').doGet('smartapp/usage/' + _this.modelFor('users.user').id).then(function (usage) {
            resolve(Ember.Object.create(usage));
          }, function (_ref2) {
            var errorCode = _ref2.errorCode,
                errorMessage = _ref2.errorMessage;

            if (errorCode === 'KN-ACC-002') {
              resolve(Ember.Object.create({
                noAccount: true,
                noSubscription: true
              }));
            } else if (errorCode === 'BIZ-SUB-002') {
              resolve(Ember.Object.create({
                noSubscription: true
              }));
            } else {
              reject({ errorCode: errorCode, errorMessage: errorMessage });
            }
          });
        }),
        // API Call #3 - Orders options
        orderOptions: new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('platform').doGet('care/order/options?userId=' + _this.modelFor('users.user').id).then(function (orderOptions) {
            resolve(Ember.Object.create(orderOptions));
          }, function (_ref3) {
            var errorCode = _ref3.errorCode,
                errorMessage = _ref3.errorMessage;

            if (errorCode === 'KN-ACC-002') {
              resolve(Ember.Object.create({
                noAccount: true,
                noSubscription: true
              }));
            } else if (errorCode === 'BIZ-SUB-002') {
              reject({ errorCode: errorCode, errorMessage: errorMessage });
            } else if (errorCode === 'KN-ACC-009') {
              // Create a dummy response - routes handle this
              resolve(Ember.Object.create({}));
            } else {
              reject({ errorCode: errorCode, errorMessage: errorMessage });
            }
          });
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('inProgress', false);
      controller.set('ssp', _environment.default.APP.ssp);
      controller.set('phoneNumber', this.modelFor('users.user').phoneNumber);
    },


    actions: {
      activate: function activate() {
        var _this2 = this;

        this.controller.set('inProgress', true);
        this.get('platform').doPost('activate', {
          userId: this.modelFor('users.user').id
        }).then(function () {
          _this2.get('notify').success('Subscription activated!');
          _this2.refresh();
        }, function (error) {
          _this2.controller.set('inProgress', false);
          _this2.send('error', error);
        });
      }
    }
  });
});