define('aira-mgmt/accounts/account/sites/site/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    platform: Ember.inject.service(),

    model: function model() {
      return this.modelFor('accounts.account.sites.site').site;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (model.fence) {
        var url = 'https://geojson.io/#data=data:application/json,' + encodeURIComponent(JSON.stringify(model.fence));
        controller.set('geojsonUrl', url);
      }
      controller.set('timezone', new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]);
    },


    actions: {
      backSite: function backSite(accountId) {
        this.transitionTo('accounts.account.sites', accountId);
      },
      editSite: function editSite(accountId, siteId) {
        this.transitionTo('accounts.account.sites.site.edit', accountId, siteId);
      }
    }
  });
});