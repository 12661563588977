define("aira-mgmt/accounts/new/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    gender: null,

    errorMessage: null,

    acceptBusinessUsers: true,

    genders: [{ text: "Male", id: "Male" }, { text: "Female", id: "Female" }, { text: "Gender Fluid", id: "Gender Fluid" }, { text: "Not Disclosed", id: "Not Disclosed" }],

    actions: {
      genderSelection: function genderSelection(selected) {
        this.set('gender', selected);
      }
    }
  });
});