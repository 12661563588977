define('aira-mgmt/router', ['exports', 'aira-mgmt/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('users', function () {
      this.route('user', { path: '/:user_id' }, function () {
        this.route('contacts', function () {
          this.route('new');
          this.route('edit', { path: '/:contact_id' });
        });
        this.route('address', function () {
          this.route('new');
          this.route('edit', { path: '/:address_id' });
        });
        this.route('service');
        this.route('chats');
        this.route('chats-v2');
        this.route('wifi', function () {
          this.route('new');
          this.route('edit', { path: '/:wifi_id' });
        });
        this.route('questions', function () {
          this.route('edit', { path: '/:question_id' });
        });
        this.route('comments');
        this.route('access', function () {
          this.route('edit', { path: '/:user_id' });
        });
        this.route('activity');
        this.route('delete');
        this.route('roles');
        this.route('settings');
        this.route('support');
        this.route('credit');
        this.route('password');

        this.route('subscription', function () {
          this.route('upgrade');
          this.route('downgrade');
          this.route('date');
          this.route('pause');
          this.route('cancel');
          this.route('minutes');
        });
        this.route('onboard');
        this.route('referrals');
        this.route('properties', function () {
          this.route('property', { path: '/:property_key' }, function () {
            this.route('edit', { path: '/:value_id' });
            this.route('new');
          });
        });
        this.route('accounts');
      });
      this.route('new');
    });
    this.route('login');
    this.route('onboard', function () {
      this.route('google', { path: '/:user_id/google' });
      this.route('wifi', { path: '/:user_id/wifi' }, function () {
        this.route('new');
      });
      this.route('interview', { path: '/:user_id/interview' });
      this.route('sendmail', { path: '/:user_id/sendmail' });
    });
    this.route('apps', function () {
      this.route('app', { path: '/:app_id' });
    });
    this.route('explorers');

    this.route('accounts', function () {
      this.route('new');
      this.route('account', { path: '/:account_id' }, function () {
        this.route('users');
        this.route('usage');
        this.route('products', function () {
          this.route('product', function () {
            this.route('new');
            this.route('edit', { path: '/:product_id' });
          });
        });
        this.route('sites', function () {
          this.route('new');
          this.route('site', { path: '/:site_id' }, function () {
            this.route('edit');
          });
        });
      });
    });
  });

  exports.default = Router;
});