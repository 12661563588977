define('aira-mgmt/users/user/settings/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    notify: Ember.inject.service(),

    model: function model() {
      var id = this.modelFor('users.user').get('id');

      return Ember.RSVP.hash({
        user: this.get('store').findRecord('user', id),
        settings: this.get('store').findRecord('setting', id)
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // Provide state service to generated controller, avoids making our own.
      controller.set('isSecondary', !model.settings.get('primary'));
    },


    actions: {
      save: function save(settings, updatedSettings) {
        var _this = this;

        settings.setProperties(updatedSettings);

        settings.save().then(function () {
          _this.set('state.isDirty', false);
          _this.get('notify').success('Settings were updated.');
        }, function (error) {
          _this.send('error', error);
        });
      }
    }
  });
});