define('aira-mgmt/users/user/service/route', ['exports', 'aira-mgmt/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      pg: {
        refreshModel: true
      }
    },

    platform: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      var id = this.modelFor('users.user').get('id');
      var pg = params.pg == null ? 0 : params.pg;

      return Ember.RSVP.hash({
        user: this.get('store').findRecord('user', id),
        service: new Promise(function (resolve, reject) {
          _this.get('platform').doGet('user/' + id + '/service-request?&page=' + pg + '&includeTestCalls=true').then(function (services) {
            services.payload.forEach(function (service) {
              service.isEditShareSession = false;
            });
            resolve(Ember.Object.create(services));
          }, function (_ref) {
            var errorCode = _ref.errorCode,
                errorMessage = _ref.errorMessage;

            reject({ errorCode: errorCode, errorMessage: errorMessage });
          });
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (controller.get('pg') == null) {
        controller.set('pg', 0);
      }
      controller.set('timezone', new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]);
    },


    actions: {
      selectServiceRequest: function selectServiceRequest(request) {
        window.open(_environment.default.APP.ssp + "/internal/report?serviceID=" + request.id, "_blank");
      },
      forceRefreshUserService: function forceRefreshUserService() {
        this.refresh();
      }
    }
  });
});