define('aira-mgmt/onboard/google/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		googleAccount: Ember.inject.service(),

		setupController: function setupController(controller, model) {
			this._super(controller, model);
			controller.set('googleUrl', this.get('googleAccount').getCreateAccountUrl(model));
			this.send('updateSubtitle', 'Google Accounts');
		},


		actions: {
			back: function back() {
				this.transitionTo('onboard.index');
			},
			next: function next() {
				// Pass model.id to force model() in step3 to get called
				this.transitionTo('onboard.wifi', this.get('currentModel.id'));
			}
		}
	});
});