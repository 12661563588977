define('aira-mgmt/users/user/questions/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),
    questions: Ember.computed.alias('state.users.user.questions'),

    notify: Ember.inject.service(),

    model: function model(p) {
      return this.get('store').findRecord('answer', p.question_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // applies row highlight on transition from row click
      this.set('questions.selectedAnswerId', model.id);
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
          transition.abort();
        } else {
          this.set('questions.selectedAnswerId', -1);
        }
      },

      save: function save(answer, answerProps) {
        var _this = this;

        answer.setProperties(answerProps);
        return answer.save().then(function () {
          _this.set('state.isDirty', false);
          _this.get('notify').success('Response was updated.');
          _this.transitionTo('users.user.questions');
        });
      },
      cancel: function cancel(answer) {
        answer.rollbackAttributes();
        this.transitionTo('users.user.questions');
      },
      delete: function _delete(answer) {
        var _this2 = this;

        answer.destroyRecord().then(function () {
          _this2.set('state.isDirty', false);
          _this2.get('notify').success('Response was deleted.');
          _this2.transitionTo('users.user.questions');
        });
      }
    }
  });
});