define('aira-mgmt/accounts/account/users/controller', ['exports', 'luxon'], function (exports, _luxon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    platform: Ember.inject.service(),

    addUserID: null,
    addUserDurationAllowed: null,
    deleteUserID: null,
    invites: null,
    primaryUserID: null,
    inviteUserEmail: null,
    deleteInviteUserEmail: null,
    deleteSecondaryUserID: null,
    editASL: false,
    addingUser: false,

    actions: {
      noop: function noop() {},
      editDurationAllowed: function editDurationAllowed(user) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.model.users[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var u = _step.value;

            // Set isEditing to true for the selected user and false for everyone else
            if (u.id === user.id) {
              Ember.set(u, 'editDurationAllowed', u.durationAllowed === -1 ? null : Math.ceil(u.durationAllowed / 60));
              Ember.set(u, 'isEditing', true);
            } else {
              Ember.set(u, 'isEditing', false);
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      },
      cancelEditDurationAllowed: function cancelEditDurationAllowed(user) {
        Ember.set(user, 'isEditing', false);
      },
      saveDurationAllowed: function saveDurationAllowed(user) {
        var _this = this;

        var durationAllowed = -1;
        if (user.editDurationAllowed) {
          if (parseInt(user.editDurationAllowed) < 1) {
            this.notify.error('Minutes allowed must be greater than 0.');
            return;
          }

          // Convert minutes to seconds
          durationAllowed = parseInt(user.editDurationAllowed) * 60;
        }

        if (durationAllowed === user.durationAllowed) {
          // Nothing changed
          Ember.set(user, 'isEditing', false);
          return;
        }

        this.platform.doPut('mgmt/account/' + user.accountId + '/user/' + user.userId + '/business', {
          durationAllowed: durationAllowed
        }).then(function () {
          _this.notify.success('Updated minutes allowed for user with ID ' + user.userId + ' in account ' + user.accountId + '.');
          Ember.set(user, 'durationAllowed', durationAllowed);
          Ember.set(user, 'isEditing', false);
        }, function (_ref) {
          var errorMessage = _ref.errorMessage;

          _this.notify.error(errorMessage);
        });
      },
      editEndDate: function editEndDate(user) {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = this.model.users[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var u = _step2.value;

            // Set isEditingEndDate to true for the selected user and false for everyone else
            if (u.id === user.id) {
              Ember.set(u, 'editEndDate', u.effectiveTo ? u.effectiveTo.split('T')[0] : null);
              Ember.set(u, 'isEditingEndDate', true);
            } else {
              Ember.set(u, 'isEditingEndDate', false);
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      },
      cancelEndDate: function cancelEndDate(user) {
        Ember.set(user, 'isEditingEndDate', false);
      },
      saveEndDate: function saveEndDate(user) {
        var _this2 = this;

        if (user.editEndDate) {
          if (new Date(user.editEndDate) < new Date()) {
            this.notify.error('Date should be greater than current date.');
            return;
          }
        }
        var effectiveTo = _luxon.DateTime.fromISO(user.editEndDate, { zone: 'America/Los_Angeles' }).setZone('UTC').toISO({ suppressMilliseconds: true });

        this.platform.doPut('mgmt/account/' + user.accountId + '/user/' + user.userId + '/business/service-end-date', {
          effectiveTo: effectiveTo
        }).then(function () {
          _this2.notify.success('Updated the end date for user with ID ' + user.userId + ' in account ' + user.accountId + '.');
          Ember.set(user, 'effectiveTo', user.editEndDate);
          Ember.set(user, 'isEditingEndDate', false);
        }, function (_ref2) {
          var errorMessage = _ref2.errorMessage;

          _this2.notify.error(errorMessage);
        });
      },
      removeEndDate: function removeEndDate(user) {
        var _this3 = this;

        this.platform.doPut('mgmt/account/' + user.accountId + '/user/' + user.userId + '/business/service-end-date', {
          effectiveTo: null
        }).then(function () {
          _this3.notify.success('Removed the end date for user with ID ' + user.userId + ' in account ' + user.accountId + '.');
          Ember.set(user, 'effectiveTo', null);
          Ember.set(user, 'isEditingEndDate', false);
        }, function (_ref3) {
          var errorMessage = _ref3.errorMessage;

          _this3.notify.error(errorMessage);
        });
      },
      editASL: function editASL(user) {
        this.model.users.forEach(function (u) {
          Ember.set(u, 'isEditingASL', u.id === user.id);
        });
        Ember.set(this, 'editASL', !user.asl);
      },
      saveASL: function saveASL(user) {
        var _this4 = this;

        this.platform.doPut('mgmt/account/' + user.accountId + '/user/' + user.userId + '/business/asl', {
          value: this.editASL
        }).then(function () {
          _this4.notify.success('Updated ASL for user with ID ' + user.userId + ' in account ' + user.accountId + '.');
          Ember.set(user, 'asl', _this4.editASL);
          Ember.set(user, 'isEditingASL', false);
        }, function (_ref4) {
          var errorMessage = _ref4.errorMessage;

          _this4.notify.error(errorMessage);
        });
      },
      cancelASL: function cancelASL(user) {
        Ember.set(user, 'isEditingASL', false);
      }
    }
  });
});