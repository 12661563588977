define('aira-mgmt/users/user/activity/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    model: function model(p) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.RSVP.hash({
          activity: _this.get('platform').doGet('mgmt/' + _this.modelFor('users.user').id + '/login')
        }).then(function (response) {
          var activity = response;
          // console.log('activity', activity);
          resolve(activity);
        }, function (_ref) {
          var errorCode = _ref.errorCode,
              errorMessage = _ref.errorMessage;

          reject({ errorCode: errorCode, errorMessage: errorMessage });
        });
      });
    },


    actions: {
      logoutApp: function logoutApp(appName) {
        var _this2 = this;

        var user = this.modelFor('users.user');
        var fullName = user.get('lastName') != null ? user.get('firstName') + ' ' + user.get('lastName') : user.get('firstName');
        var userId = user.get('id');
        if (confirm('Are you sure you want to logout ' + fullName + ' with user ID ' + userId + ' from ' + appName + '?')) {
          this.get('platform').doPost(['mgmt', userId, 'login', appName, 'logout'].join('/')).then(function () {
            _this2.get('notify').success('User has been logged out of ' + appName);
            _this2.transitionTo('users.user');
          }, function (error) {
            _this2.send('error', error);
          });
        }
      },
      banApp: function banApp(appName) {
        var _this3 = this;

        var user = this.modelFor('users.user');
        var fullName = user.get('lastName') != null ? user.get('firstName') + ' ' + user.get('lastName') : user.get('firstName');
        var userId = user.get('id');
        if (confirm('Are you sure you want to ban ' + fullName + ' with user ID ' + userId + ' from ' + appName + '?')) {
          this.get('platform').doPost(['mgmt', userId, 'login', appName, 'blacklist'].join('/')).then(function () {
            _this3.get('notify').success('User has been banned from ' + appName);
            _this3.transitionTo('users.user');
          }, function (error) {
            _this3.send('error', error);
          });
        }
      },
      logoutUser: function logoutUser() {
        var _this4 = this;

        var user = this.modelFor('users.user');
        // console.log('logoutUser', user);
        var fullName = user.get('lastName') != null ? user.get('firstName') + ' ' + user.get('lastName') : user.get('firstName');
        var userId = user.get('id');
        if (confirm('Are you sure you want to logout ' + fullName + ' with user ID ' + userId + '?')) {
          this.get('platform').doDelete(['mgmt', userId, 'login'].join('/')).then(function () {
            _this4.get('notify').success('User has been logged out of everything');
            _this4.transitionTo('users.user');
          }, function (error) {
            _this4.send('error', error);
          });
        }
      }
    }
  });
});