define('aira-mgmt/services/google-account', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		getCreateAccountUrl: function getCreateAccountUrl(user) {
			var dateOfBirth = (user.get('dateOfBirth') || '--').split('-'); // year-month-day

			var gmail = '';
			if (user.get('firstName') && user.get('lastName')) {
				gmail = user.get('firstName').toLowerCase() + '.' + user.get('lastName').toLowerCase() + '.aira';
			}

			var url = 'https://accounts.google.com/SignUp?' + ['continue=https%3A%2F%2Fplus.google.com%2F', 'FirstName=' + (user.get('firstName') || ''), 'LastName=' + (user.get('lastName') || ''), 'GmailAddress=' + gmail, 'BirthMonth=' + dateOfBirth[1], 'BirthDay=' + dateOfBirth[2], 'BirthYear=' + dateOfBirth[0], 'Gender=' + (user.get('gender') || '').toUpperCase(), 'RecoveryPhoneNumber=' + (user.get('phoneNumber') || ''), 'RecoveryEmailAddress=support%40aira.io'].join('&');

			return url;
		}
	});
});