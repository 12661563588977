define('aira-mgmt/mixins/model-editor', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var _dataKey = null;
	var _modelKey = null;
	var _propNames = null;

	/**
  * Enables dirty checking for the route that this is mixed into.
  * Relies on state.isDirty flag.
  * Route should inspect this flag in willTransition() and reset it to false on successful save.
  */
	exports.default = Ember.Mixin.create({

		state: Ember.inject.service(),

		_bindModelEditor: function _bindModelEditor(datakey, modelKey, propNames) {
			_dataKey = datakey;
			_modelKey = modelKey;
			_propNames = propNames;
		},
		didReceiveAttrs: function didReceiveAttrs() {
			var _this = this;

			this._super.apply(this, arguments);
			this.set(_dataKey, Ember.Object.create(this.get(_modelKey).getProperties(_propNames)));
			_propNames.forEach(function (name) {
				return _this.get(_dataKey).addObserver(name, _this, _this._modelEditorOnChange);
			});
		},


		_modelEditorOnChange: function _modelEditorOnChange(data, key) {
			var model = this.get(_modelKey);
			var newValue = data.get(key);
			var oldValue = model.get(key);

			if (newValue === '') {
				newValue = null;
			}

			if (newValue !== oldValue) {
				this.set('state.isDirty', true);
			} else {
				var dirty = false;
				_propNames.forEach(function (name) {
					dirty = model.get(name) !== data.get(name);
				});

				this.set('state.isDirty', dirty);
			}
		}
	});
});