define('aira-mgmt/users/user/chats/route', ['exports', 'aira-mgmt/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      pg: {
        refreshModel: true
      }
    },

    platform: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      var userId = this.modelFor('users.user').id;
      return Ember.RSVP.hash({
        chats: new Promise(function (resolve, reject) {
          _this.platform.doGet('chat?userId=' + userId + '&page=' + params.pg).then(function (chats) {
            resolve(Ember.Object.create(chats));
          }, function (_ref) {
            var errorCode = _ref.errorCode,
                errorMessage = _ref.errorMessage;

            reject({ errorCode: errorCode, errorMessage: errorMessage });
          });
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('timezone', new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]);
    },


    actions: {
      openChatReport: function openChatReport(chat) {
        window.open(_environment.default.APP.ssp + '/internal/chatReport?chatId=' + chat.id, '_blank');
      }
    }
  });
});