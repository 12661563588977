define('aira-mgmt/users/user/chats-v2/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['pg'],

    pg: 1,

    timezone: null,

    disableBack: Ember.computed('pg', function () {
      return parseInt(this.pg) === 1;
    }),

    disableNext: Ember.computed('model.rooms.hasNext', function () {
      return this.model.rooms.hasNext !== true;
    }),

    actions: {
      previousPage: function previousPage() {
        this.decrementProperty('pg');
      },
      nextPage: function nextPage() {
        this.incrementProperty('pg');
      }
    }
  });
});