define('aira-mgmt/accounts/account/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    notify: Ember.inject.service(),

    beforeModel: function beforeModel() {}
  }

  // actions: {
  //   save() {
  //     profile.setProperties(profileProps);
  //     return profile.save().then(() => {
  //       this.set('state.isDirty', false);
  //       this.get('notify').success('Profile was updated.');
  //     }, (error) => {
  //       this.send('error', error);
  //     });
  //   }
  // }
  );
});