define('aira-mgmt/routes/explorers', ['exports', 'aira-mgmt/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model() {
			return $.ajax({
				url: [_environment.default.APP.host, _environment.default.APP.namespace, 'registration', 'count'].join('/') + '?plan=explorer',
				headers: {
					'X-API-Key': _environment.default.APP.X_API_Key
				}
			}).then(function (data) {
				return {
					count: data
				};
			});
		},
		activate: function activate() {
			var self = this;
			Ember.run.later(function () {
				self.tick();
			}, 1000 * 60);
		},
		tick: function tick() {
			this.refresh();
		}
	});
});