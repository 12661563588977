define('aira-mgmt/users/user/contacts/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // Provide state service to generated controller, avoids making our own.
      controller.set('state', this.get('state'));
    },


    actions: {
      // received from table row click
      selectContact: function selectContact(contact) {
        this.transitionTo('users.user.contacts.edit', contact);
      }
    }
  });
});