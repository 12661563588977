define('aira-mgmt/users/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['type', 'q'],

    activeId: null,

    // Binds search input to query param
    q: null,
    type: "",
    search: null,
    searchType: "",
    activeUserId: -1,

    propertyOptions: [{ text: 'Test Mode', id: 'TEST' }, { text: 'Test User', id: 'TEST_USER' }],

    actions: {
      selectPropertyType: function selectPropertyType(property) {
        this.set("propertyType", property);
        console.log(this.get("propertyType"));
      }
    }
  });
});