define('aira-mgmt/helpers/money', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.money = money;
	function money(params /*, hash*/) {
		if (Ember.$.isNumeric(params[0])) {
			var value = Math.abs(params[0]) >= 100 ? params[0] / 100 : params[0];
			return (0, _formatMoney.default)(value, params[1], 2, ',', '.', '%v %s');
		}
	}

	exports.default = Ember.Helper.helper(money);
});