define('aira-mgmt/routes/login', ['exports', 'aira-mgmt/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    firebase: Ember.inject.service(),
    session: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      if (_environment.default.environment !== 'development') {
        // Handle the sign-in with redirect result.
        this.set('controller.isLoading', true);
        this.get('session').authenticate('authenticator:firebase').then(function () {
          return _this.transitionTo('users');
        }).catch(function () {}) // This is expected when we're not authenticated. Ignore.
        .finally(function () {
          return _this.set('controller.isLoading', false);
        });
      }
    },

    actions: {
      login: function login() {
        var _this2 = this;

        if (_environment.default.environment !== 'development') {
          // Sign-in with redirect.
          this.get('firebase').login();
        } else {
          // Sign-in with popup.
          this.set('controller.isLoading', true);
          this.get('session').authenticate('authenticator:firebase').then(function () {
            return _this2.transitionTo('users');
          }).catch(function () {}) // This is expected when we're not authenticated. Ignore.
          .finally(function () {
            return _this2.set('controller.isLoading', false);
          });
        }
      }
    }
  });
});