define('aira-mgmt/onboard/interview/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			this.send('updateSubtitle', 'Info and Preferences');
		},


		actions: {
			back: function back() {
				this.transitionTo('onboard.wifi', this.get('currentModel.id'));
			},
			next: function next() {
				// Pass model.id to force model() in step3 to get called
				this.transitionTo('onboard.sendmail', this.get('currentModel.id'));
			}
		}
	});
});