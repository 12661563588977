define('aira-mgmt/components/user-roles/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		state: Ember.inject.service(),

		appNames: ['AIRA SMART', 'AGENT DASHBOARD', 'MGMT DASHBOARD', 'SSP', 'CARE TOOLS', 'DESKTOP'],

		roleNames: ['ADMIN', 'AIRA AGENT', 'LV USER', 'RESELLER', 'ACCESS PARTNER'],

		/**
   * Passed-in roles from model
   */
		appRoles: null,

		/**
   * Deep copy of appRoles
   */
		data: null,

		/**
   * Callback receives data
   */
		onSave: null,

		didReceiveAttrs: function didReceiveAttrs() {
			this._super.apply(this, arguments);

			var appRoles = this.get('appRoles');

			if (appRoles == null) {
				appRoles = [];
			}

			var data = [];

			appRoles.forEach(function (app) {
				var roles = [];
				app.roles.forEach(function (role) {
					roles.push(role);
				});

				data.push({
					appName: app.appName,
					roles: roles
				});
			});

			this.set('data', data);
		},


		actions: {
			toggleRole: function toggleRole(appName, roleName, e) {
				var isChecked = e.target.checked;
				var data = this.get('data');

				var app = data.find(function (app) {
					return appName === app.appName;
				});
				// No roles for given appName existed
				if (!app) {
					data.push({
						appName: appName,
						roles: [roleName]
					});
				}
				// Roles existed, and we are adding
				else if (isChecked) {
						app.roles.push(roleName);
					}
					// Roles existed, and we are removing
					else {
							var roleIndex = app.roles.indexOf(roleName);
							app.roles.splice(roleIndex, 1);
							if (app.roles.length === 0) {
								for (var i = 0, l = data.length; i < l; i++) {
									if (app.appName === data[i].appName) {
										data.splice(i, 1);
										break;
									}
								}
							}
						}

				// Always mark dirty on toggle. Not currently maintaining a check to see if
				// the end result of toggling actually left us in a dirty state like other screens do.
				this.set('state.isDirty', true);
			},
			save: function save() {
				this.get('onSave')(this.get('data'));
			}
		}
	});
});