define('aira-mgmt/users/user/properties/property/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),
    notify: Ember.inject.service(),
    platform: Ember.inject.service(),

    // SAVE HELPER FUNCTIONS
    getChildrenData: function getChildrenData(data, parentKey) {
      // console.log('getChildrenData~BEGIN', data, parentKey);
      var childrenData = {};

      Object.keys(data).forEach(function (key) {
        if (key.startsWith(parentKey) && key !== parentKey) {
          childrenData[key] = data[key];
        }
      });

      // console.log('getChildrenData~END', childrenData);
      return childrenData;
    },
    getChildrenPayload: function getChildrenPayload(data, depth) {
      var _this = this;

      // console.log('getChildrenPayload~BEGIN', data, depth);

      var childrenPayload = {};

      Object.keys(data).forEach(function (key) {
        if (key.split('.').length === depth) {
          var childrenValue = {};
          childrenValue['value'] = data[key];
          childrenPayload[key] = [];

          var moreChildrenData = _this.getChildrenData(data, key);

          if (!Ember.isEmpty(moreChildrenData)) {
            var moreChildrenPayload = _this.getChildrenPayload(moreChildrenData, depth + 1);
            childrenValue['children'] = moreChildrenPayload;
          }

          childrenPayload[key].push(childrenValue);
        }
      });

      // console.log('getChildrenPayload~END', childrenPayload);
      return childrenPayload;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.resetPendingUpdates();
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
          transition.abort();
        }
      },

      save: function save(data) {
        var _this2 = this;

        this.set('controller.inProgress', true);
        // console.log(this.refresh());

        // console.log('save DATA', data);

        var payload = {};
        var parentKey = this.modelFor('users.user.properties.property').key;

        payload['value'] = data[parentKey];
        var childrenData = this.getChildrenData(data, parentKey);

        if (!Ember.isEmpty(childrenData)) {
          var childrenPayload = this.getChildrenPayload(childrenData, 2);
          payload['children'] = childrenPayload;
        }

        // console.log('payload', payload);

        this.get('platform').doPost('user/' + this.modelFor('users.user').id + '/property/' + parentKey + '/value', payload).then(function () {
          _this2.get('notify').success('New Property Value Successfully Added!');
          _this2.get('controller').resetPendingUpdates();
          _this2.transitionTo('users.user.properties');
        }, function (error) {
          _this2.send('error', error);
        }).finally(function () {
          _this2.set('controller.inProgress', false);
        });
      },
      cancel: function cancel() {
        this.transitionTo('users.user.properties.property');
      }
    }
  });
});