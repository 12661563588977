define('aira-mgmt/onboard/index/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		actions: {
			next: function next(user, userProps) {
				var _this = this;

				user.setProperties(userProps);
				user.save().then(function () {
					return _this.transitionTo('onboard.google', user);
				}, function (error) {
					return _this.send('error', error);
				});
			}
		}
	});
});