define('aira-mgmt/accounts/account/products/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    platform: Ember.inject.service(),

    model: function model() {
      var _this = this;

      var accountId = this.modelFor('accounts.account').account.accountId;
      return Ember.RSVP.hash({
        product: new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('platform').doGet('mgmt/account/' + accountId + '/product').then(function (response) {
            console.log('response data:', response);
            resolve(response);
          }, function (_ref) {
            var errorCode = _ref.errorCode,
                errorMessage = _ref.errorMessage;

            reject({ errorCode: errorCode, errorMessage: errorMessage });
          });
        }),
        promotion: new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('platform').doGet('mgmt/account/' + accountId + '/promotion').then(function (response) {
            console.log('response data:', response);
            resolve(response);
          }, function (_ref2) {
            var errorCode = _ref2.errorCode,
                errorMessage = _ref2.errorMessage;

            reject({ errorCode: errorCode, errorMessage: errorMessage });
          });
        })
      });
    },


    actions: {
      selectProduct: function selectProduct(accountId, product, productOrPromotion) {
        console.log('Selected ' + productOrPromotion + ': ' + product.id + ' in account: ' + accountId);
        this.controller.set('activeId', product.id);
        this.transitionTo('accounts.account.products.product.edit', accountId, product.id);
      },
      createNew: function createNew() {
        console.log('add product pressed');
        this.transitionTo('accounts.account.products.product.new');
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('timezone', new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]);
    }
  });
});