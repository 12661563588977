define('aira-mgmt/users/user/properties/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    model: function model() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        /**
         * Gets all properties, and all properties set on a user and merges the values from
         * the properties set on the user into the list of all properties. This allows us
         * to display all properties for updating, even if there is no value for the user.
         */
        Ember.RSVP.hash({
          properties: _this.get('platform').doGet('user/property'),
          values: _this.get('platform').doGet('user/' + _this.modelFor('users.user').id + '/property/value')
        }).then(function (response) {
          var properties = response.properties.payload;
          var values = response.values.payload;
          var propsUnordered = {};
          var propsOrdered = {};

          // console.log('properties', properties);
          // console.log('values', values);

          properties.forEach(function (prop) {
            var pv = values[prop.key];
            if (pv) prop['value'] = pv.map(function (v) {
              return v.value;
            });
            propsUnordered[prop.key] = prop;
            propsUnordered[prop.key]['childrenList'] = [];
            prop.children.forEach(function (child) {
              var moreChildren = _this.getChildren(child);
              Object.keys(moreChildren).forEach(function (key) {
                propsUnordered[prop.key]['childrenList'].push(moreChildren[key].name);
              });
              propsUnordered = Object.assign(propsUnordered, moreChildren);
            });
          });

          Object.keys(propsUnordered).sort().forEach(function (key) {
            propsOrdered[key] = propsUnordered[key];
          });

          resolve(propsOrdered);
        }, function (_ref) {
          var errorCode = _ref.errorCode,
              errorMessage = _ref.errorMessage;

          reject({ errorCode: errorCode, errorMessage: errorMessage });
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.resetPendingUpdates();
    },
    getChildren: function getChildren(prop) {
      var _this2 = this;

      // console.log('getChildren', prop);
      var children = {};
      prop['parent'] = prop.key.split('.')[0];
      prop['depth'] = prop.key.split('.').length - 1;
      children[prop.key] = prop;
      if (prop.hasOwnProperty('children')) {
        prop.children.forEach(function (child) {
          var moreChildren = _this2.getChildren(child);
          children = Object.assign(children, moreChildren);
        });
      }
      return children;
    },


    actions: {
      selectRow: function selectRow(key) {
        this.transitionTo('users.user.properties.property', key);
      }
    }
  });
});