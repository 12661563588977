define('aira-mgmt/adapters/user', ['exports', 'aira-mgmt/adapters/application'], function (exports, _application) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = _application.default.extend({

				platform: Ember.inject.service(),

				query: function query(store, type, _query) {
						var wrappedQuery = _query.filter.search.trim();
						var searchType = _query.filter.type;
						var url = 'search?e=U&type=' + searchType + '&q=' + wrappedQuery;
						return this.platform.doGet(url);
				},
				queryRecord: function queryRecord(store, type, query) {
						var url = 'user?login=' + query.filter.login;
						return this.platform.doGet(url);
				}
		});
});