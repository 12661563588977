define('aira-mgmt/components/questions/question-multi-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    q: null,

    htmlId: null,

    options: null,

    selectedOptionValues: null,

    displayedFollowups: null,

    update: function update() {},

    init: function init() {
      this._super.apply(this, arguments);
      var q = this.get('q');

      this.set('htmlId', 'q-' + q.property.key);

      var options = [];
      q.property.options.forEach(function (opt) {
        if (opt.enabled) {
          options.push({
            id: opt.value,
            text: opt.value
          });
        }
      });

      this.set('options', options);

      var selectedOptionValues = [];
      q.values.forEach(function (uv) {
        selectedOptionValues.push(uv.value);
      });

      this.updateSelectionsAndDisplayedFollowups(selectedOptionValues);
    },
    updateSelectionsAndDisplayedFollowups: function updateSelectionsAndDisplayedFollowups(selectedOptionValues) {
      var q = this.get('q');

      this.set('selectedOptionValues', selectedOptionValues);

      var displayedFollowups = [];

      q.questionFollowUps.forEach(function (fu) {
        var match = selectedOptionValues.some(function (so) {
          return fu.values.includes(so);
        });
        if (match) {
          displayedFollowups.push(fu.questionFollowUp);
        }
      });

      // This will cause a re-render if changed.
      this.set('displayedFollowups', displayedFollowups);
    },


    actions: {
      select: function select(selectedOptionValues /* array */) {
        this.updateSelectionsAndDisplayedFollowups(selectedOptionValues);
        this.update(this.get('q'), selectedOptionValues);
      },
      update: function update(q, value) {
        this.update(q, value);
      }
    }
  });
});