define('aira-mgmt/components/user-setting/component', ['exports', 'aira-mgmt/mixins/model-editor'], function (exports, _modelEditor) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var settingsPropNames = ['testUser'];

	exports.default = Ember.Component.extend(_modelEditor.default, {

		state: Ember.inject.service(),

		title: null,

		settings: null,

		onSave: null,

		onCancel: null,

		onDelete: null,

		data: null,

		isSecondary: null,

		init: function init() {
			this._super.apply(this, arguments);
			this._bindModelEditor('data', 'settings', settingsPropNames);
		},


		actions: {
			save: function save() {
				var settingsProps = this.get('data').getProperties(settingsPropNames);
				this.get('onSave')(this.get('settings'), settingsProps);
			},
			cancel: function cancel() {
				this.set('state.isDirty', false);
				this.get('onCancel')(this.get('settings'));
			}
		}
	});
});