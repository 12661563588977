define('aira-mgmt/users/user/address/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),
    address: Ember.computed.alias('state.users.user.address'),

    notify: Ember.inject.service(),

    model: function model(p) {
      return this.get('store').findRecord('address', p.address_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // applies row highlight on transition from row click
      this.set('address.selectedAddressId', model.id);
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
          transition.abort();
        } else {
          this.set('address.selectedAddressId', -1);
        }
      },

      save: function save(address, addressProps) {
        var _this = this;

        address.setProperties(addressProps);
        return address.save().then(function () {
          _this.set('state.isDirty', false);
          _this.get('notify').success('Address was updated.');
          _this.transitionTo('users.user.address');
        });
      },
      cancel: function cancel(address) {
        address.rollbackAttributes();
        this.transitionTo('users.user.address');
      },
      delete: function _delete(address) {
        var _this2 = this;

        address.destroyRecord().then(function () {
          console.log('delete');
          _this2.set('state.isDirty', false);
          _this2.get('notify').success('Address was deleted.');
          _this2.transitionTo('users.user.address');
        });
      }
    }
  });
});