define("aira-mgmt/sentry", ["exports", "@sentry/browser", "@sentry/integrations/esm/ember", "aira-mgmt/config/environment"], function (exports, _browser, _ember, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.startSentry = startSentry;
  function startSentry() {
    if (_environment.default.APP.mgmt_version === undefined) {
      _environment.default.APP.mgmt_version = 'local'; // if running locally
    }
    _browser.init({
      dsn: _environment.default.APP.sentry,
      environment: _environment.default.APP.env,
      release: _environment.default.APP.mgmt_version,
      integrations: [new _ember.Ember()],

      beforeSend: function beforeSend(event, hint) {
        var error = hint.originalException;
        // ignore errors from localhost
        if (event.request.url.includes('localhost')) {
          return null;
        }
        // ignore aborted route transitions from the router
        if (error && error.name === "TransitionAborted") {
          return null;
        }
        console.log(event);
        return event;
      }
    });
  }
});