define('aira-mgmt/users/user/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    initialTab: null,

    beforeModel: function beforeModel(t) {
      // Handles setting the activeTab when deep-linking
      // targetName is: users.user.{activeTab}.index
      this.initialTab = t.targetName.split('.')[2];
    },


    model: function model(p) {
      return this.get('store').findRecord('user', p.user_id);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('users').set('activeId', model.get('id'));
      controller.set('activeTab', this.initialTab);
    },

    deactivate: function deactivate() {
      this.controllerFor('users').set('activeId', -1);
    },


    actions: {
      loading: function loading(transition) {
        var controller = this.get('controller');
        if (controller) {
          controller.set('isLoading', true);
          transition.promise.finally(function () {
            controller.set('isLoading', false);
          });
        }
      },
      willTransition: function willTransition(t) {
        // Handles keeping the activeTab updated when changing
        // targetName is: users.user.{activeTab}.index
        var target = t.targetName.split('.')[2];
        this.controller.set('activeTab', target);
      }
    }
  });
});