define('aira-mgmt/users/user/roles/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    notify: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // Provide state service to generated controller, avoids making our own.
      controller.set('state', this.get('state'));
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
          transition.abort();
        }
      },

      save: function save(updatedRoles) {
        var _this = this;

        this.set('currentModel.appRoles', updatedRoles);
        this.get('currentModel').save().then(function () {
          _this.get('notify').success('Roles were updated.');
          _this.set('state.isDirty', false);
        });
      }
    }
  });
});