define('aira-mgmt/users/user/delete/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    notify: Ember.inject.service(),

    actions: {
      deleteUser: function deleteUser(user) {
        var _this = this;

        var fullName = user.get('lastName') != null ? user.get('firstName') + ' ' + user.get('lastName') : user.get('firstName');
        var userId = user.get('id');
        if (confirm('Are you sure you want to delete ' + fullName + ' with user ID ' + userId + '?')) {
          user.destroyRecord().then(function () {
            _this.set('state.users.selectedUserId', -1);
            _this.get('notify').success('User was deleted.');
            _this.transitionTo('users.index');
          });
        }
      }
    }
  });
});