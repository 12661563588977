define('aira-mgmt/users/user/properties/property/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),
    notify: Ember.inject.service(),
    platform: Ember.inject.service(),

    model: function model(p) {
      return p;
    },
    afterModel: function afterModel(model) {
      // redirect if doesn't have model
      if (!model.id) {
        this.transitionTo('users.user.properties.property');
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // applies row highlight on transition from row click
      this.set('state.users.user.properties.property.selectedPropertyValueId', model.id);
    },


    // SAVE HELPER FUNCTIONS
    getChildrenData: function getChildrenData(data, parentKey) {
      // console.log('getChildrenData~BEGIN', data, parentKey);
      var childrenData = {};

      Object.keys(data).forEach(function (key) {
        if (key.startsWith(parentKey) && key !== parentKey) {
          childrenData[key] = data[key];
        }
      });

      // console.log('getChildrenData~END', childrenData);
      return childrenData;
    },
    getChildrenPayload: function getChildrenPayload(data, depth) {
      var _this = this;

      // console.log('getChildrenPayload~BEGIN', data, depth);

      var childrenPayload = {};

      Object.keys(data).forEach(function (key) {
        if (key.split('.').length === depth) {
          var childrenValue = {};
          childrenValue['value'] = data[key];
          childrenPayload[key] = [];

          var moreChildrenData = _this.getChildrenData(data, key);

          if (!Ember.isEmpty(moreChildrenData)) {
            var moreChildrenPayload = _this.getChildrenPayload(moreChildrenData, depth + 1);
            childrenValue['children'] = moreChildrenPayload;
          }

          childrenPayload[key].push(childrenValue);
        }
      });

      // console.log('getChildrenPayload~END', childrenPayload);
      return childrenPayload;
    },
    makeDataHelper: function makeDataHelper(data) {
      // console.log('makeDataHelper~BEGIN', data);
      var dataBefore = {};
      dataBefore[data.property.key] = data.value;

      var dataChildren = this.makeData(data.children);
      if (!Ember.isEmpty(dataChildren)) dataBefore = Object.assign(dataBefore, dataChildren);

      // console.log('makeDataHelper~END', dataBefore);
      return dataBefore;
    },
    makeData: function makeData(data) {
      var _this2 = this;

      // console.log('makeData~BEGIN', data);

      var dataChildren = {};

      Object.keys(data).forEach(function (key) {
        data[key].forEach(function (value) {
          dataChildren[key] = value.value;

          var dataMoreChildren = _this2.makeData(value.children);
          if (!Ember.isEmpty(dataMoreChildren)) dataChildren = Object.assign(dataChildren, dataMoreChildren);
        });
      });

      // console.log('makeData~END', dataChildren);
      return dataChildren;
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
          transition.abort();
        } else {
          this.set('state.users.user.properties.property.selectedPropertyValueId', -1);
        }
      },

      delete: function _delete() {
        var _this3 = this;

        this.set('controller.inProgress', true);

        // console.log('delete user property value id', this.modelFor('users.user.properties.property.edit').id);

        this.get('platform').doDelete('user/' + this.modelFor('users.user').id + '/property/' + this.modelFor('users.user.properties.property.edit').key + '/value/' + this.modelFor('users.user.properties.property.edit').id).then(function () {
          _this3.get('notify').success('Property Value Successfully Deleted!');
          _this3.get('controller').resetPendingUpdates();
        }, function (error) {
          _this3.send('error', error);
        }).finally(function () {
          _this3.set('controller.inProgress', false);
          _this3.modelFor('users.user.properties.property').reload();
        });
      },
      save: function save(data) {
        var _this4 = this;

        this.set('controller.inProgress', true);
        // console.log('save', data);

        var dataBefore = this.makeDataHelper(this.modelFor('users.user.properties.property.edit').value);
        // console.log('dataBefore', dataBefore);

        var dataNew = dataBefore;

        Object.keys(data).forEach(function (key) {
          dataNew[key] = data[key];
        });

        // console.log('dataNew', dataNew)

        var payload = {};
        var parentKey = this.modelFor('users.user.properties.property.edit').value.property.key;

        payload['value'] = dataNew[parentKey];
        var childrenData = this.getChildrenData(dataNew, parentKey);

        if (!Ember.isEmpty(childrenData)) {
          var childrenPayload = this.getChildrenPayload(childrenData, 2);
          payload['children'] = childrenPayload;
        }

        // console.log('payload', payload);

        this.get('platform').doPut('user/' + this.modelFor('users.user').id + '/property/' + parentKey + '/value/' + this.modelFor('users.user.properties.property.edit').id, payload).then(function () {
          _this4.get('notify').success('Edit Property Value Successfully Updated!');
          _this4.get('controller').resetPendingUpdates();
          _this4.transitionTo('users.user.properties');
        }, function (error) {
          _this4.send('error', error);
        }).finally(function () {
          _this4.set('controller.inProgress', false);
        });
      },
      cancel: function cancel() {
        this.transitionTo('users.user.properties.property');
      }
    }
  });
});