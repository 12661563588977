define('aira-mgmt/adapters/wifi', ['exports', 'aira-mgmt/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		query: function query(store, type, _query) {
			var url = [this.host, this.namespace, 'user', 'wifiprofile'].join('/');
			return this.ajax(url, 'GET', { data: { userId: _query.filter.userId } });
		},
		queryRecord: function queryRecord(store, type, query) {
			throw 'cannot query single wifi profile';
		},
		createRecord: function createRecord(store, type, snapshot) {
			var url = [this.host, this.namespace, 'user', 'wifiprofile'].join('/');
			var data = this.serialize(snapshot, { includeId: true });
			return this.ajax(url, 'POST', { data: data });
		},
		updateRecord: function updateRecord(store, type, snapshot) {
			var url = [this.host, this.namespace, 'user', 'wifiprofile'].join('/');
			var data = this.serialize(snapshot, { includeId: true });
			return this.ajax(url, 'PUT', { data: data });
		},
		deleteRecord: function deleteRecord(store, type, snapshot) {
			var url = [this.host, this.namespace, 'user', 'wifiprofile'].join('/');
			url = [url, '?userId=', this._encodeUrl(snapshot.record.get('userId')), '&id=', snapshot.id].join('');
			return this.ajax(url, 'DELETE');
		}
	});
});