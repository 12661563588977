define('aira-mgmt/users/user/properties/property/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        notify: Ember.inject.service(),

        platform: Ember.inject.service(),

        state: Ember.inject.service(),

        model: function model(p) {
            var _this = this;

            return new Ember.RSVP.Promise(function (resolve, reject) {
                Ember.RSVP.hash({
                    property: _this.get('platform').doGet('user/property/' + p.property_key),
                    values: _this.get('platform').doGet('user/' + _this.modelFor('users.user').id + '/property/' + p.property_key + '/value')
                }).then(function (response) {
                    var property = response.property;
                    var values = response.values.payload;

                    // console.log('property', property);
                    // if(values) console.log('values', values);

                    var props = {
                        key: p.property_key,
                        property: property,
                        isMulti: false
                    };
                    if (values) {
                        props.property.values = values;
                        props.values = _this.setProperties(property, values);
                        props.depth = _this.getValueDepth(props.values) - 1;
                    }
                    if (props.property.type.startsWith('MULTI')) {
                        props.property.isMulti = true;
                    } else {
                        if (values && values.length) {
                            props.value = values[0].value;
                            props.children = values[0].children;
                        }
                    }

                    resolve(props);
                }, function (_ref) {
                    var errorCode = _ref.errorCode,
                        errorMessage = _ref.errorMessage;

                    reject({ errorCode: errorCode, errorMessage: errorMessage });
                });
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('state', this.get('state'));
            controller.resetPendingUpdates();
        },
        setProperties: function setProperties(property, values) {
            var newValues = [];
            values.forEach(function (value) {
                value.property = property;
                value.type = property.type;
                if (value.property.type.startsWith('MULTI')) {
                    value.property['isMulti'] = true;
                } else {
                    value.property['isMulti'] = false;
                }
                newValues.push(value);
            });
            return newValues;
        },
        getValueDepth: function getValueDepth(values) {
            var _this2 = this;

            // console.log('getValueDepth~BEGIN', values);
            var max = 0;
            values.forEach(function (value) {
                var count = _this2.getValueDepthHelper(value);
                if (count > max) max = count;
            });
            return max;
        },
        getValueDepthHelper: function getValueDepthHelper(value) {
            var _this3 = this;

            // console.log('getValueDepthHelper~BEGIN', value);
            var count = 1; // count self
            if (value.children) {
                Object.keys(value.children).forEach(function (child) {
                    var max = 0;
                    value.children[child].forEach(function (childValue) {
                        var childCount = _this3.getValueDepthHelper(childValue);
                        if (childCount > max) max = childCount;
                    });
                    count += max; // add max children count
                });
            }
            return count;
        },


        // SAVE HELPER FUNCTIONS
        getChildrenData: function getChildrenData(data, parentKey) {
            // console.log('getChildrenData~BEGIN', data, parentKey);
            var childrenData = {};

            Object.keys(data).forEach(function (key) {
                if (key.startsWith(parentKey) && key !== parentKey) {
                    childrenData[key] = data[key];
                }
            });

            // console.log('getChildrenData~END', childrenData);
            return childrenData;
        },
        getChildrenPayload: function getChildrenPayload(data, depth) {
            var _this4 = this;

            // console.log('getChildrenPayload~BEGIN', data, depth);

            var childrenPayload = {};

            Object.keys(data).forEach(function (key) {
                if (key.split('.').length === depth) {
                    var childrenValue = {};
                    childrenValue['value'] = data[key];
                    childrenPayload[key] = [];

                    var moreChildrenData = _this4.getChildrenData(data, key);

                    if (!Ember.isEmpty(moreChildrenData)) {
                        var moreChildrenPayload = _this4.getChildrenPayload(moreChildrenData, depth + 1);
                        childrenValue['children'] = moreChildrenPayload;
                    }

                    childrenPayload[key].push(childrenValue);
                }
            });

            // console.log('getChildrenPayload~END', childrenPayload);
            return childrenPayload;
        },
        makeDataHelper: function makeDataHelper(data) {
            // console.log('makeDataHelper~BEGIN', data);
            var dataBefore = {};
            dataBefore[data.property.key] = data.value;

            var dataChildren = this.makeData(data.children);
            if (!Ember.isEmpty(dataChildren)) dataBefore = Object.assign(dataBefore, dataChildren);

            // console.log('makeDataHelper~END', dataBefore);
            return dataBefore;
        },
        makeData: function makeData(data) {
            var _this5 = this;

            // console.log('makeData~BEGIN', data);

            var dataChildren = {};

            Object.keys(data).forEach(function (key) {
                data[key].forEach(function (value) {
                    dataChildren[key] = value.value;

                    var dataMoreChildren = _this5.makeData(value.children);
                    if (!Ember.isEmpty(dataMoreChildren)) dataChildren = Object.assign(dataChildren, dataMoreChildren);
                });
            });

            // console.log('makeData~END', dataChildren);
            return dataChildren;
        },


        actions: {
            // received from table row click
            selectValue: function selectValue(value) {
                this.transitionTo('users.user.properties.property.edit', { value: value, id: value.id });
            },
            save: function save(data) {
                var _this6 = this;

                this.set('controller.inProgress', true);
                // console.log('save', data);
                // console.log('model', this.modelFor('users.user.properties.property').key);

                if (Ember.isEmpty(data)) return;

                var dataBefore = this.modelFor('users.user.properties.property').values;
                if (dataBefore && dataBefore.length) {
                    dataBefore = this.makeDataHelper(dataBefore[0]);
                }
                // console.log('dataBefore', dataBefore);

                var dataNew = dataBefore || {};

                Object.keys(data).forEach(function (key) {
                    dataNew[key] = data[key];
                });

                var payload = {};
                var parentKey = this.modelFor('users.user.properties.property').key;

                payload['value'] = dataNew[parentKey];
                var childrenData = this.getChildrenData(dataNew, parentKey);

                if (!Ember.isEmpty(childrenData)) {
                    var childrenPayload = this.getChildrenPayload(childrenData, 2);
                    payload['children'] = childrenPayload;
                }

                // console.log('payload', payload);

                this.get('platform').doPut('user/' + this.modelFor('users.user').id + '/property/' + parentKey + '/value', [payload]).then(function () {
                    _this6.get('notify').success('Edit Property Value Successfully Updated!');
                    _this6.get('controller').resetPendingUpdates();
                    _this6.transitionTo('users.user.properties');
                }, function (error) {
                    _this6.send('error', error);
                }).finally(function () {
                    _this6.set('controller.inProgress', false);
                });
            }
        }
    });
});