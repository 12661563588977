define('aira-mgmt/users/user/onboard/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    model: function model() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('platform').doGet('user/' + _this.modelFor('users.user').id + '/question?category=management').then(function (response) {
          resolve(response.payload);
        }, function (_ref) {
          var errorCode = _ref.errorCode,
              errorMessage = _ref.errorMessage;

          reject({ errorCode: errorCode, errorMessage: errorMessage });
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.resetPendingUpdates();
    },


    /*
     [
     {"key":"mobilityTool","values":[{"value":"Guide Dog"}]},
     {"key":"mobilityTool.guideDog","values":[{"value":"boss"}]},
     {"key":"orientationPref","values":[{"value":"Left & Right"}]},
     {"key":"escalator","values":[{"value":"true"}]}
     ]
     */
    actions: {
      save: function save(pendingUpdates) {
        var _this2 = this;

        this.set('controller.inProgress', true);

        var payload = Object.keys(pendingUpdates).map(function (key) {
          var values = void 0;
          if (Ember.typeOf(pendingUpdates[key]) === 'string') {
            values = pendingUpdates[key] === '' ? [] : [{ value: pendingUpdates[key] }];
          } else if (Ember.typeOf(pendingUpdates[key]) === 'boolean') {
            values = { value: pendingUpdates[key] ? 'true' : 'false' };
          } else {
            values = pendingUpdates[key].map(function (answer) {
              return { value: answer };
            });
          }
          return { key: key, values: values };
        });

        this.get('platform').doPost('user/' + this.modelFor('users.user').id + '/property/value/batch', payload).then(function () {
          _this2.get('notify').success('Profile Successfully Changed!');
          _this2.get('controller').resetPendingUpdates();
        }, function (error) {
          _this2.send('error', error);
        }).finally(function () {
          _this2.set('controller.inProgress', false);
        });
      }
    }
  });
});