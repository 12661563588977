define('aira-mgmt/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    accountCode: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    phoneticFirstName: _emberData.default.attr('string'),
    login: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    language: _emberData.default.attr(),
    gender: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    phoneVerified: _emberData.default.attr('boolean'),
    dateOfBirth: _emberData.default.attr('string'),
    appRoles: _emberData.default.attr(),
    properties: _emberData.default.attr(),
    accounts: _emberData.default.attr(),
    guest: _emberData.default.attr('boolean'),

    contacts: _emberData.default.hasMany('contact'),
    addresses: _emberData.default.hasMany('address'),
    answers: _emberData.default.hasMany('answer'),

    buildAiProgramJoined: Ember.computed('properties', function () {
      var props = this.get('properties');
      if (props && props.hasOwnProperty('buildAiProgramJoined') && props.buildAiProgramJoined.length > 0) {
        return props.buildAiProgramJoined[0].value;
      } else {
        return null;
      }
    }),

    isBts: Ember.computed('properties', function () {
      var props = this.get('properties');
      if (!props || !props.hasOwnProperty('btsUser')) {
        return false;
      }

      if (Array.isArray(props.btsUser) && props.btsUser.length > 0) {
        return props.btsUser[0].value;
      }
      return false;
    }),

    isSecondary: Ember.computed('accounts', function () {
      var accounts = this.get('accounts');
      if (accounts) {
        return accounts.some(function (account) {
          return account.userType === 'secondary';
        });
      } else {
        return false;
      }
    }),

    isGuest: Ember.computed('guest', function () {
      return this.get('guest');
    })
  });
});