define('aira-mgmt/onboard/wifi/new/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		state: Ember.inject.service(),

		notify: Ember.inject.service(),

		model: function model() {
			var login = this.modelFor('onboard.wifi').user.get('login');

			return this.get('store').createRecord('wifi', {
				username: login,
				priority: null,
				ssid: null,
				securityType: null,
				secretkey: null,
				type: null
			});
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			this.send('toggleNext', false);
		},
		deactivate: function deactivate() {
			// Not done in cancel incase user navigates away by another means
			this.get('currentModel').rollbackAttributes();
			this.send('toggleNext', true);
		},


		actions: {
			willTransition: function willTransition(transition) {
				if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
					transition.abort();
				}
			},

			save: function save(profile, wifiProps) {
				var _this = this;

				profile.setProperties(wifiProps);
				profile.save().then(function () {
					_this.set('state.isDirty', false);
					_this.send('newWifiProfile', _this.get('currentModel'));
					_this.get('notify').success('Wifi profile was added.');
					_this.transitionTo('onboard.wifi');
				});
			},
			cancel: function cancel() {
				this.transitionTo('onboard.wifi');
			}
		}
	});
});