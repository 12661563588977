define('aira-mgmt/helpers/minutes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.minutes = minutes;
  function minutes(input) {
    var minutes = ~~(input / 60); // ~~ removes the decimal
    var seconds = input % 60;
    return minutes + ' minutes ' + seconds + ' seconds';
  }

  exports.default = Ember.Helper.helper(minutes);
});