define('aira-mgmt/users/user/chats-v2/route', ['exports', 'aira-mgmt/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      pg: {
        refreshModel: true
      }
    },

    firebase: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      var userId = this.modelFor('users.user').id;
      return Ember.RSVP.hash({
        rooms: new Ember.RSVP.Promise(function (resolve, reject) {
          _this.firebase.getIdToken().then(function (idToken) {
            $.ajax({
              url: _environment.default.APP.aira_api_base_url + '/chat/user/' + userId + '/rooms?page=' + (parseInt(params.pg) > 1 ? params.pg : 1),
              method: 'GET',
              contentType: 'application/json',
              headers: {
                'Authorization': 'Bearer ' + idToken
              },
              success: function success(data) {
                resolve(data);
              },
              error: function error(jqXHR) {
                reject(jqXHR);
              }
            });
          }).catch(function (error) {
            return reject(error);
          });
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('timezone', new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]);
    },


    actions: {
      openChatReport: function openChatReport(room) {
        window.open(_environment.default.APP.ssp + '/internal/chatReport?chatId=' + room.roomId, '_blank');
      }
    }
  });
});