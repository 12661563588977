define('aira-mgmt/accounts/account/usage/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    platform: Ember.inject.service(),

    model: function model() {
      var _this = this;

      var accountId = this.modelFor('accounts.account').account.accountId;
      return Ember.RSVP.hash({
        usage: new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('platform').doGet('mgmt/account/' + accountId + '/usage-state').then(function (response) {
            resolve(response);
          }, function (_ref) {
            var errorCode = _ref.errorCode,
                errorMessage = _ref.errorMessage;

            reject({ errorCode: errorCode, errorMessage: errorMessage });
            return [];
          });
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('timezone', new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]);
    },


    actions: {}
  });
});