define('aira-mgmt/users/user/password/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('newPassword', null);
    },

    actions: {
      changePassword: function changePassword(newPassword) {
        var _this = this;

        var userId = this.modelFor('users.user').get('id');

        if (newPassword) {
          this.get('platform').doPut(['mgmt', userId, 'password'].join('/'), {
            newPassword: newPassword
          }).then(function () {
            _this.get('notify').success('Password updated!');
            _this.transitionTo('users.user');
          }, function (error) {
            _this.send('error', error);
          });
        }
      }
    }
  });
});