define('aira-mgmt/accounts/account/sites/site/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    platform: Ember.inject.service(),

    model: function model(p) {
      var _this = this;

      return Ember.RSVP.hash({
        site: new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('platform').doGet('access/site/' + p.site_id).then(function (response) {
            resolve(response);
          }, function (_ref) {
            var errorCode = _ref.errorCode,
                errorMessage = _ref.errorMessage;

            reject({ errorCode: errorCode, errorMessage: errorMessage });
          });
        })
      });
    }
  });
});