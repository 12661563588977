define('aira-mgmt/components/properties/property-text/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    p: null,

    property: null,

    hasValue: null,

    htmlId: null,

    value: null,

    update: function update() {},

    init: function init() {
      this._super.apply(this, arguments);
      var p = this.get('p');

      var property = {};
      var hasValue = false;
      var value = null;

      if (p.hasOwnProperty('value')) {
        // MULTI/EDIT
        property = p.property;
        value = p.value;
        hasValue = true;

        // go thru p children and set properties
        if (property.hasOwnProperty('children')) {
          property.children.forEach(function (child) {
            var valueChildren = p.children[child.key];
            if (valueChildren) {
              valueChildren.forEach(function (valueChild) {
                valueChild.property = child;
                valueChild.type = child.type;
              });
              p.children[child.key] = valueChildren;
            } else {
              p.children[child.key] = [child];
            }
          });
        }
      } else {
        // SINGLE
        property = p;
        if (!property.hasOwnProperty('id')) {
          property = p.property;
        }
      }

      var key = property.key.split('.');
      if (key.length > 1) {
        property.parent = key[0];
      }

      this.set('htmlId', 'p-' + property.key);
      this.set('p', p);
      this.set('property', property);
      this.set('hasValue', hasValue);
      this.set('value', value);
    },


    actions: {
      change: function change(value) {
        this.update(this.get('p'), value);
      },
      update: function update(p, value) {
        this.update(p, value);
      }
    }
  });
});