define('aira-mgmt/components/user-address/component', ['exports', 'aira-mgmt/mixins/model-editor'], function (exports, _modelEditor) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var addressPropNames = ['address1', 'address2', 'city', 'state', 'zip', 'addresstype', 'country'];

	exports.default = Ember.Component.extend(_modelEditor.default, {

		state: Ember.inject.service(),

		title: null,

		address: null,

		onSave: null,

		onCancel: null,

		onDelete: null,

		data: null,

		init: function init() {
			this._super.apply(this, arguments);
			this._bindModelEditor('data', 'address', addressPropNames);
		},


		actions: {
			selectAddressType: function selectAddressType(addressType) {
				this.set('data.addresstype', addressType);
			},
			selectCountry: function selectCountry(country) {
				this.set('data.country', country);
			},
			save: function save() {
				var addressProps = this.get('data').getProperties(addressPropNames);
				this.get('onSave')(this.get('address'), addressProps);
			},
			cancel: function cancel() {
				this.set('state.isDirty', false);
				this.get('onCancel')(this.get('address'));
			},
			delete: function _delete() {
				if (confirm('Are you sure?')) {
					this.get('onDelete')(this.get('address'));
				}
			}
		},

		addressTypeOptions: [{ text: 'Home', id: 'home' }, { text: 'Work', id: 'work' }, { text: 'Shipping', id: 'shipping' }],

		// ISO-3166-1 alpha-2 country code (part of the list)
		countryOptions: [{ text: 'United States', id: 'US' }, { text: 'Canada', id: 'CA' }, { text: 'Australia', id: 'AU' }, { text: 'New Zealand', id: 'NZ' }, { text: 'United Kingdom', id: 'GB' }, { text: 'Ireland', id: 'IE' }]

		// ISO-3166-1 alpha-2 (full list)
		// countryOptions: [
		// 	{text: 'Afghanistan', id: 'AF'}
		// 	{text: 'Aland Islands', id: 'AX'}
		// 	{text: 'Albania', id: 'AL'}
		// 	{text: 'Algeria', id: 'DZ'}
		// 	{text: 'American Samoa', id: 'AS'}
		// 	{text: 'Andorra', id: 'AD'}
		// 	{text: 'Angola', id: 'AO'}
		// 	{text: 'Anguilla', id: 'AI'}
		// 	{text: 'Antarctica', id: 'AQ'}
		// 	{text: 'Antigua And Barbuda', id: 'AG'}
		// 	{text: 'Argentina', id: 'AR'}
		// 	{text: 'Armenia', id: 'AM'}
		// 	{text: 'Aruba', id: 'AW'}
		// 	{text: 'Australia', id: 'AU'}
		// 	{text: 'Austria', id: 'AT'}
		// 	{text: 'Azerbaijan', id: 'AZ'}
		// 	{text: 'Bahamas', id: 'BS'}
		// 	{text: 'Bahrain', id: 'BH'}
		// 	{text: 'Bangladesh', id: 'BD'}
		// 	{text: 'Barbados', id: 'BB'}
		// 	{text: 'Belarus', id: 'BY'}
		// 	{text: 'Belgium', id: 'BE'}
		// 	{text: 'Belize', id: 'BZ'}
		// 	{text: 'Benin', id: 'BJ'}
		// 	{text: 'Bermuda', id: 'BM'}
		// 	{text: 'Bhutan', id: 'BT'}
		// 	{text: 'Bolivia', id: 'BO'}
		// 	{text: 'Bosnia And Herzegovina', id: 'BA'}
		// 	{text: 'Botswana', id: 'BW'}
		// 	{text: 'Bouvet Island', id: 'BV'}
		// 	{text: 'Brazil', id: 'BR'}
		// 	{text: 'British Indian Ocean Territory', id: 'IO'}
		// 	{text: 'Brunei Darussalam', id: 'BN'}
		// 	{text: 'Bulgaria', id: 'BG'}
		// 	{text: 'Burkina Faso', id: 'BF'}
		// 	{text: 'Burundi', id: 'BI'}
		// 	{text: 'Cambodia', id: 'KH'}
		// 	{text: 'Cameroon', id: 'CM'}
		// 	{text: 'Canada', id: 'CA'}
		// 	{text: 'Cape Verde', id: 'CV'}
		// 	{text: 'Cayman Islands', id: 'KY'}
		// 	{text: 'Central African Republic', id: 'CF'}
		// 	{text: 'Chad', id: 'TD'}
		// 	{text: 'Chile', id: 'CL'}
		// 	{text: 'China', id: 'CN'}
		// 	{text: 'Christmas Island', id: 'CX'}
		// 	{text: 'Cocos (Keeling) Islands', id: 'CC'}
		// 	{text: 'Colombia', id: 'CO'}
		// 	{text: 'Comoros', id: 'KM'}
		// 	{text: 'Congo', id: 'CG'}
		// 	{text: 'Congo, Democratic Republic', id: 'CD'}
		// 	{text: 'Cook Islands', id: 'CK'}
		// 	{text: 'Costa Rica', id: 'CR'}
		// 	{text: 'Cote D\'Ivoire', id: 'CI'}
		// 	{text: 'Croatia', id: 'HR'}
		// 	{text: 'Cuba', id: 'CU'}
		// 	{text: 'Cyprus', id: 'CY'}
		// 	{text: 'Czech Republic', id: 'CZ'}
		// 	{text: 'Denmark', id: 'DK'}
		// 	{text: 'Djibouti', id: 'DJ'}
		// 	{text: 'Dominica', id: 'DM'}
		// 	{text: 'Dominican Republic', id: 'DO'}
		// 	{text: 'Ecuador', id: 'EC'}
		// 	{text: 'Egypt', id: 'EG'}
		// 	{text: 'El Salvador', id: 'SV'}
		// 	{text: 'Equatorial Guinea', id: 'GQ'}
		// 	{text: 'Eritrea', id: 'ER'}
		// 	{text: 'Estonia', id: 'EE'}
		// 	{text: 'Ethiopia', id: 'ET'}
		// 	{text: 'Falkland Islands (Malvinas)', id: 'FK'}
		// 	{text: 'Faroe Islands', id: 'FO'}
		// 	{text: 'Fiji', id: 'FJ'}
		// 	{text: 'Finland', id: 'FI'}
		// 	{text: 'France', id: 'FR'}
		// 	{text: 'French Guiana', id: 'GF'}
		// 	{text: 'French Polynesia', id: 'PF'}
		// 	{text: 'French Southern Territories', id: 'TF'}
		// 	{text: 'Gabon', id: 'GA'}
		// 	{text: 'Gambia', id: 'GM'}
		// 	{text: 'Georgia', id: 'GE'}
		// 	{text: 'Germany', id: 'DE'}
		// 	{text: 'Ghana', id: 'GH'}
		// 	{text: 'Gibraltar', id: 'GI'}
		// 	{text: 'Greece', id: 'GR'}
		// 	{text: 'Greenland', id: 'GL'}
		// 	{text: 'Grenada', id: 'GD'}
		// 	{text: 'Guadeloupe', id: 'GP'}
		// 	{text: 'Guam', id: 'GU'}
		// 	{text: 'Guatemala', id: 'GT'}
		// 	{text: 'Guernsey', id: 'GG'}
		// 	{text: 'Guinea', id: 'GN'}
		// 	{text: 'Guinea-Bissau', id: 'GW'}
		// 	{text: 'Guyana', id: 'GY'}
		// 	{text: 'Haiti', id: 'HT'}
		// 	{text: 'Heard Island & Mcdonald Islands', id: 'HM'}
		// 	{text: 'Holy See (Vatican City State)', id: 'VA'}
		// 	{text: 'Honduras', id: 'HN'}
		// 	{text: 'Hong Kong', id: 'HK'}
		// 	{text: 'Hungary', id: 'HU'}
		// 	{text: 'Iceland', id: 'IS'}
		// 	{text: 'India', id: 'IN'}
		// 	{text: 'Indonesia', id: 'ID'}
		// 	{text: 'Iran, Islamic Republic Of', id: 'IR'}
		// 	{text: 'Iraq', id: 'IQ'}
		// 	{text: 'Ireland', id: 'IE'}
		// 	{text: 'Isle Of Man', id: 'IM'}
		// 	{text: 'Israel', id: 'IL'}
		// 	{text: 'Italy', id: 'IT'}
		// 	{text: 'Jamaica', id: 'JM'}
		// 	{text: 'Japan', id: 'JP'}
		// 	{text: 'Jersey', id: 'JE'}
		// 	{text: 'Jordan', id: 'JO'}
		// 	{text: 'Kazakhstan', id: 'KZ'}
		// 	{text: 'Kenya', id: 'KE'}
		// 	{text: 'Kiribati', id: 'KI'}
		// 	{text: 'Korea', id: 'KR'}
		// 	{text: 'Kuwait', id: 'KW'}
		// 	{text: 'Kyrgyzstan', id: 'KG'}
		// 	{text: 'Lao People\'s Democratic Republic', id: 'LA'}
		// 	{text: 'Latvia', id: 'LV'}
		// 	{text: 'Lebanon', id: 'LB'}
		// 	{text: 'Lesotho', id: 'LS'}
		// 	{text: 'Liberia', id: 'LR'}
		// 	{text: 'Libyan Arab Jamahiriya', id: 'LY'}
		// 	{text: 'Liechtenstein', id: 'LI'}
		// 	{text: 'Lithuania', id: 'LT'}
		// 	{text: 'Luxembourg', id: 'LU'}
		// 	{text: 'Macao', id: 'MO'}
		// 	{text: 'Macedonia', id: 'MK'}
		// 	{text: 'Madagascar', id: 'MG'}
		// 	{text: 'Malawi', id: 'MW'}
		// 	{text: 'Malaysia', id: 'MY'}
		// 	{text: 'Maldives', id: 'MV'}
		// 	{text: 'Mali', id: 'ML'}
		// 	{text: 'Malta', id: 'MT'}
		// 	{text: 'Marshall Islands', id: 'MH'}
		// 	{text: 'Martinique', id: 'MQ'}
		// 	{text: 'Mauritania', id: 'MR'}
		// 	{text: 'Mauritius', id: 'MU'}
		// 	{text: 'Mayotte', id: 'YT'}
		// 	{text: 'Mexico', id: 'MX'}
		// 	{text: 'Micronesia, Federated States Of', id: 'FM'}
		// 	{text: 'Moldova', id: 'MD'}
		// 	{text: 'Monaco', id: 'MC'}
		// 	{text: 'Mongolia', id: 'MN'}
		// 	{text: 'Montenegro', id: 'ME'}
		// 	{text: 'Montserrat', id: 'MS'}
		// 	{text: 'Morocco', id: 'MA'}
		// 	{text: 'Mozambique', id: 'MZ'}
		// 	{text: 'Myanmar', id: 'MM'}
		// 	{text: 'Namibia', id: 'NA'}
		// 	{text: 'Nauru', id: 'NR'}
		// 	{text: 'Nepal', id: 'NP'}
		// 	{text: 'Netherlands', id: 'NL'}
		// 	{text: 'Netherlands Antilles', id: 'AN'}
		// 	{text: 'New Caledonia', id: 'NC'}
		// 	{text: 'New Zealand', id: 'NZ'}
		// 	{text: 'Nicaragua', id: 'NI'}
		// 	{text: 'Niger', id: 'NE'}
		// 	{text: 'Nigeria', id: 'NG'}
		// 	{text: 'Niue', id: 'NU'}
		// 	{text: 'Norfolk Island', id: 'NF'}
		// 	{text: 'Northern Mariana Islands', id: 'MP'}
		// 	{text: 'Norway', id: 'NO'}
		// 	{text: 'Oman', id: 'OM'}
		// 	{text: 'Pakistan', id: 'PK'}
		// 	{text: 'Palau', id: 'PW'}
		// 	{text: 'Palestinian Territory, Occupied', id: 'PS'}
		// 	{text: 'Panama', id: 'PA'}
		// 	{text: 'Papua New Guinea', id: 'PG'}
		// 	{text: 'Paraguay', id: 'PY'}
		// 	{text: 'Peru', id: 'PE'}
		// 	{text: 'Philippines', id: 'PH'}
		// 	{text: 'Pitcairn', id: 'PN'}
		// 	{text: 'Poland', id: 'PL'}
		// 	{text: 'Portugal', id: 'PT'}
		// 	{text: 'Puerto Rico', id: 'PR'}
		// 	{text: 'Qatar', id: 'QA'}
		// 	{text: 'Reunion', id: 'RE'}
		// 	{text: 'Romania', id: 'RO'}
		// 	{text: 'Russian Federation', id: 'RU'}
		// 	{text: 'Rwanda', id: 'RW'}
		// 	{text: 'Saint Barthelemy', id: 'BL'}
		// 	{text: 'Saint Helena', id: 'SH'}
		// 	{text: 'Saint Kitts And Nevis', id: 'KN'}
		// 	{text: 'Saint Lucia', id: 'LC'}
		// 	{text: 'Saint Martin', id: 'MF'}
		// 	{text: 'Saint Pierre And Miquelon', id: 'PM'}
		// 	{text: 'Saint Vincent And Grenadines', id: 'VC'}
		// 	{text: 'Samoa', id: 'WS'}
		// 	{text: 'San Marino', id: 'SM'}
		// 	{text: 'Sao Tome And Principe', id: 'ST'}
		// 	{text: 'Saudi Arabia', id: 'SA'}
		// 	{text: 'Senegal', id: 'SN'}
		// 	{text: 'Serbia', id: 'RS'}
		// 	{text: 'Seychelles', id: 'SC'}
		// 	{text: 'Sierra Leone', id: 'SL'}
		// 	{text: 'Singapore', id: 'SG'}
		// 	{text: 'Slovakia', id: 'SK'}
		// 	{text: 'Slovenia', id: 'SI'}
		// 	{text: 'Solomon Islands', id: 'SB'}
		// 	{text: 'Somalia', id: 'SO'}
		// 	{text: 'South Africa', id: 'ZA'}
		// 	{text: 'South Georgia And Sandwich Isl.', id: 'GS'}
		// 	{text: 'Spain', id: 'ES'}
		// 	{text: 'Sri Lanka', id: 'LK'}
		// 	{text: 'Sudan', id: 'SD'}
		// 	{text: 'Suriname', id: 'SR'}
		// 	{text: 'Svalbard And Jan Mayen', id: 'SJ'}
		// 	{text: 'Swaziland', id: 'SZ'}
		// 	{text: 'Sweden', id: 'SE'}
		// 	{text: 'Switzerland', id: 'CH'}
		// 	{text: 'Syrian Arab Republic', id: 'SY'}
		// 	{text: 'Taiwan', id: 'TW'}
		// 	{text: 'Tajikistan', id: 'TJ'}
		// 	{text: 'Tanzania', id: 'TZ'}
		// 	{text: 'Thailand', id: 'TH'}
		// 	{text: 'Timor-Leste', id: 'TL'}
		// 	{text: 'Togo', id: 'TG'}
		// 	{text: 'Tokelau', id: 'TK'}
		// 	{text: 'Tonga', id: 'TO'}
		// 	{text: 'Trinidad And Tobago', id: 'TT'}
		// 	{text: 'Tunisia', id: 'TN'}
		// 	{text: 'Turkey', id: 'TR'}
		// 	{text: 'Turkmenistan', id: 'TM'}
		// 	{text: 'Turks And Caicos Islands', id: 'TC'}
		// 	{text: 'Tuvalu', id: 'TV'}
		// 	{text: 'Uganda', id: 'UG'}
		// 	{text: 'Ukraine', id: 'UA'}
		// 	{text: 'United Arab Emirates', id: 'AE'}
		// 	{text: 'United Kingdom', id: 'GB'}
		// 	{text: 'United States', id: 'US'}
		// 	{text: 'United States Outlying Islands', id: 'UM'}
		// 	{text: 'Uruguay', id: 'UY'}
		// 	{text: 'Uzbekistan', id: 'UZ'}
		// 	{text: 'Vanuatu', id: 'VU'}
		// 	{text: 'Venezuela', id: 'VE'}
		// 	{text: 'Viet Nam', id: 'VN'}
		// 	{text: 'Virgin Islands, British', id: 'VG'}
		// 	{text: 'Virgin Islands, U.S.', id: 'VI'}
		// 	{text: 'Wallis And Futuna', id: 'WF'}
		// 	{text: 'Western Sahara', id: 'EH'}
		// 	{text: 'Yemen', id: 'YE'}
		// 	{text: 'Zambia', id: 'ZM'}
		// 	{text: 'Zimbabwe', id: 'ZW'}
		// ]
	});
});