define('aira-mgmt/authorizers/platform', ['exports', 'ember-simple-auth/authorizers/base', 'aira-mgmt/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    authorize: function authorize(sessionData, block) {
      block('X-API-Key', _environment.default.APP.X_API_Key);
      block('X-Aira-Token', sessionData.token);
      block('X-User-Id', sessionData.id);
    }
  });
});