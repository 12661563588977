define('aira-mgmt/users/user/address/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),
    address: Ember.computed.alias('state.users.user.address'),

    notify: Ember.inject.service(),

    model: function model() {
      this.set('address.selectedAddressId', -1);

      return this.get('store').createRecord('address', {
        userId: this.modelFor('users.user'),
        address1: null,
        address2: null,
        address3: null,
        addresstype: null,
        city: null,
        country: null,
        county: null,
        state: null,
        status: null,
        validfrom: null,
        zip: null
      });
    },
    deactivate: function deactivate() {
      // Not done in cancel incase user navigates away by another means
      this.get('currentModel').rollbackAttributes();
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
          transition.abort();
        }
      },

      save: function save(address, addressProps) {
        var _this = this;

        address.setProperties(addressProps);
        address.save().then(function () {
          _this.set('state.isDirty', false);
          _this.get('notify').success('Address was added.');
          _this.transitionTo('users.user.address');
        });
      },
      cancel: function cancel() {
        this.transitionTo('users.user.address');
      }
    }
  });
});