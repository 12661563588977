define('aira-mgmt/components/user-answer/component', ['exports', 'aira-mgmt/mixins/model-editor'], function (exports, _modelEditor) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var answerPropNames = ['qcode', 'questionText', 'answerText'];

	exports.default = Ember.Component.extend(_modelEditor.default, {

		state: Ember.inject.service(),

		title: null,

		address: null,

		onSave: null,

		onCancel: null,

		onDelete: null,

		data: null,

		init: function init() {
			this._super.apply(this, arguments);
			this._bindModelEditor('data', 'answer', answerPropNames);
		},


		actions: {
			save: function save() {
				var answerProps = this.get('data').getProperties(answerPropNames);
				this.get('onSave')(this.get('answer'), answerProps);
			},
			cancel: function cancel() {
				this.set('state.isDirty', false);
				this.get('onCancel')(this.get('answer'));
			},
			delete: function _delete() {
				if (confirm('Are you sure?')) {
					this.get('onDelete')(this.get('answer'));
				}
			}
		}
	});
});