define('aira-mgmt/components/select-multiple/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['form-group'],

		/* The label value */
		label: null,

		/* All possible options as array of {id, text} */
		options: null,

		/* Current selections */
		selectedOptionIds: null,

		/* Handler when selection added receiving (id) */
		onSelect: null,

		size: 1,

		init: function init() {
			this._super.apply(this, arguments);

			this.set('size', Math.min(6, this.get('options').length));
		},
		didInsertElement: function didInsertElement() {
			var _this = this;

			this.$('select').on('change', function () {
				_this.get('onSelect')(_this.$('select').val());
			});
		},
		willDestroyElement: function willDestroyElement() {
			this.$('select').off('change');
		}
	});
});