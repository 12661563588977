define('aira-mgmt/helpers/sr-status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.active = active;
  function active(params /*, hash*/) {
    var print = '';
    var status = params[0];
    switch (status) {
      case 'CANCEL':
        print = 'User Cancelled';
        break;
      case 'TIMEOUT':
        print = 'Timed Out Waiting';
        break;
      case 'OUT_OF_MINUTES':
        print = 'Out of Minutes';
        break;
      default:
        print = status;
        break;
    }

    return print;
  }

  exports.default = Ember.Helper.helper(active);
});