define('aira-mgmt/users/user/access/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    platform: Ember.inject.service(),

    notify: Ember.inject.service(),

    model: function model() {
      return this.modelFor('users.user.access');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },


    actions: {
      editAccess: function editAccess(access) {
        this.transitionTo('users.user.access.edit', access);
      }
    }
  });
});