define('aira-mgmt/apps/app/route', ['exports', 'ember-changeset'], function (exports, _emberChangeset) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model(p) {
			// Since our parent route always fetches the records, we know it will be there
			// on hard-refresh. Normally model is passed in transitionTo
			return this.get('store').peekRecord('app', p.app_id);
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			controller.set('changeset', new _emberChangeset.default(model));
			this.controllerFor('apps').set('activeId', model.get('id'));
		},


		actions: {
			willTransition: function willTransition(transition) {
				if (this.controller.get('changeset.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
					transition.abort();
					return false;
				} else {
					this.controllerFor('apps').set('activeId', -1);
				}
			}
		}
	});
});