define('aira-mgmt/users/user/onboard/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    pendingUpdates: {},

    inProgress: false,

    resetPendingUpdates: function resetPendingUpdates() {
      this.set('pendingUpdates', {});
    },


    actions: {
      update: function update(q, input) {
        this.get('pendingUpdates')[q.property.key] = input;
      }
    }
  });
});